import { FileObject } from "hooks/useExtractFilesFromUrl";
import ClaimsTable from "./ClaimsTable";
import { ClaimsTablesConfig } from "./types";
import { WorkerContextWrapper } from "./worker/WorkerContext";

type Props = {
  config: ClaimsTablesConfig;
  files: FileObject;
};

const ClaimsTables = ({ config, files }: Props) => {
  const { tables } = config;

  if (!tables || tables?.length === 0) {
    return null;
  }

  return (
    <WorkerContextWrapper>
      {tables.map((table, idx) => {
        return <ClaimsTable config={table} files={files} tableIndex={idx} key={table.name.concat(String(idx))} />;
      })}
    </WorkerContextWrapper>
  );
};

export default ClaimsTables;
