import pako from "pako";
import tar from "tar-stream";
import { QueryFunction, useQuery } from "@tanstack/react-query";

export type FileObject = {
  [key: string]: Uint8Array;
};

type QueryKey = ["extractFilesFromUrl", string];

export const useExtractFilesFromUrl = (packageUrl: string) => {
  const queryKey: QueryKey = ["extractFilesFromUrl", packageUrl];

  const queryFn: QueryFunction<FileObject, QueryKey> = async ({ queryKey }) => {
    const [, url] = queryKey;

    const contractPackage = await fetch(url);

    if (!contractPackage.ok) {
      throw new Error(`HTTP error! status: ${contractPackage.status}`);
    }

    const arrayBuffer = await contractPackage.arrayBuffer();
    const decompressed = pako.ungzip(new Uint8Array(arrayBuffer));
    const files: FileObject = {};

    await new Promise((resolve, reject) => {
      const extract = tar.extract();

      extract.on("entry", (header: any, stream: any, next: any) => {
        let fileData: Uint8Array[] = [];

        stream.on("data", (chunk: Uint8Array) => {
          fileData.push(chunk);
        });

        stream.on("end", () => {
          const uint8Array = new Uint8Array(fileData.reduce<number[]>((acc, val) => acc.concat(Array.from(val)), []));
          files[header.name] = uint8Array;
          next();
        });

        stream.resume();
      });

      extract.on("finish", resolve);
      extract.on("error", reject);

      extract.end(decompressed);
    });

    return files;
  };

  return useQuery<FileObject, Error, FileObject, QueryKey>({ queryKey, queryFn, enabled: !!packageUrl });
};
