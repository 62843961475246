import * as Comlink from "comlink";
import { createContext, type ReactNode, useContext, useEffect, useMemo } from "react";
import { type ClaimsTableWorker } from "./claimsTables.worker";

function createWorker() {
  const worker = new Worker(new URL("./claimsTables.worker", import.meta.url), {
    name: "Coinweb.offchain.cm.worker",
    type: "module",
  });

  const api = Comlink.wrap<ClaimsTableWorker>(worker);

  const cleanup = () => {
    api[Comlink.releaseProxy]();
    worker.terminate();
  };

  return { api, cleanup };
}

function useWorker() {
  const worker = useMemo(createWorker, []);

  useEffect(() => {
    return () => {
      worker.cleanup();
    };
  }, [worker]);

  return worker;
}
export type TransformWorker = ReturnType<typeof useWorker>;

const WorkerContext = createContext<TransformWorker | undefined>(undefined);

const WorkerContextWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <WorkerContext.Provider value={useWorker()}>{children}</WorkerContext.Provider>;
};

const useWorkerContext = () => {
  const context = useContext(WorkerContext);
  if (!context) {
    throw new Error("useWorkerContext must be used within a WorkerContext.Provider");
  }
  return context;
};

export { WorkerContextWrapper, useWorkerContext };

export default WorkerContext;
