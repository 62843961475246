import "./L2TxLogCard.css";
import { memo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { NetworkName } from "../../generated/graphql";
import Typography from "@mui/material/Typography";
import jsonParseRawContent from "utils/jsonParseRawContent";
import { RawContentData } from "utils/types";
import findRawDataElement from "utils/findRawDataElement";
import createRawContentTxloadedState from "utils/createRawContentTxloadedState";
import createRawContentContactExecutedState from "utils/createRawContentContactExecutedState";
import handleTxIds from "utils/handleTxIds";
import {
  L2TxLogMuiCardContent,
  L2TxLogDivider,
  L2TxLogMuiCard,
  MOBILE_WIDTH,
} from "components/TableContainer/CustomizedMui";
import { FullTxLogGql, ContactExecutedGql } from "../../generated/graphql";
import { FullTxLogGql as devFullTxLogGql } from "../../generated/graphql-devnet";
import RawContentModal from "../Modals/RawContentModal";
import RawContentVisualizerModal from "../ModalsVisializer/RawContentVisualizerModal";
import { BlockChains } from "utils/constants";
import TxContractExecutedSection from "./TxContractExecutedSection";
import TxLoadedSection from "./TxLoadedSection";
import LegacyCwebTxLoadedSection from "./LegacyCwebTxLoadedSection";
import TxLogErrorSection from "./TxLogErrorSection";
import { Fade, Tooltip } from "@mui/material";
import CopyIcon from "components/CopyIcon";
import checkIssuerAlias from "utils/checkIssuerAlias";
import { useGlobalIndex } from "hooks/useGlobalIndex";
import { Alias } from "shared/components";
import { BlockchainDataContextProvider } from "./context";

type L2TxLogCardProps = {
  title?: string;
  data: FullTxLogGql[] | devFullTxLogGql[];
  width: any;
  setNetwork: (n: NetworkName | undefined | 0) => void;
};

const L2TxLogCard = (props: L2TxLogCardProps) => {
  const { title, data, width, setNetwork } = props;
  const [rawTxLoaded, setRawTxLoaded] = useState<string[]>([]);
  const [rawContextJsonObj, setRawContextJsonObj] = useState<RawContentData[]>([]);
  const [rawContext, setRawContext] = useState(false);
  const [rawContextVisualiser, setRawContextVisualiser] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [alias, setAlias] = useState<string | undefined>(undefined);
  const { contractTemplates, interpreters, contractInstances } = useGlobalIndex();

  const handleClose = () => {
    setRawContext(false);
  };

  const handleCloseVisualizer = () => {
    setRawContextVisualiser(false);
    const newSearchParams = new URLSearchParams(searchParams);

    if (newSearchParams.has("visualizer")) {
      newSearchParams.delete("visualizer");
    }
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    data.forEach((item) => {
      if (item.network) {
        setNetwork(item.network as unknown as NetworkName);
      }
    });
  }, [data, setNetwork]);

  useEffect(() => {
    if (searchParams.get("visualizer") === "true") {
      setRawContextVisualiser(true);
    }
  }, [searchParams]);

  useEffect(() => {
    const loadedRawContents: string[] = [];
    const jsonRawObjs: RawContentData[] = [];
    data.forEach((item) => {
      if (item.rawContent) {
        const rawContentString = JSON.stringify(item.rawContent);
        loadedRawContents.push(rawContentString);

        const jsonRaw = jsonParseRawContent(rawContentString);
        let jsonrawObj = createRawContentTxloadedState(jsonRaw);
        const finalJsonRowObj = createRawContentContactExecutedState(jsonrawObj, jsonRaw);
        jsonRawObjs.push(finalJsonRowObj);
      }
    });

    setRawTxLoaded(loadedRawContents);
    setRawContextJsonObj(jsonRawObjs);
  }, [data]);

  useEffect(() => {
    data.forEach((item) => {
      if (item.txIssuer) {
        const issuerAlias = checkIssuerAlias(item.txIssuer, interpreters, contractTemplates, contractInstances);
        if (issuerAlias) setAlias(issuerAlias);
      }
    });
  }, [data, contractTemplates, interpreters, contractInstances]);

  return (
    <div
      className="L2txLogCard-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      {data.map((L2Data: any, index: number) => {
        const blockchain = BlockChains[L2Data.network as unknown as NetworkName];

        return (
          <BlockchainDataContextProvider blockchainData={blockchain} key={index}>
            <L2TxLogMuiCard sx={{ minWidth: 100, marginTop: index > 0 ? "20px" : undefined }}>
              <L2TxLogMuiCardContent sx={{ minWidth: 100 }}>
                <div className="L2TxLogInfo-container" style={{ textIndent: "0px !important" }}>
                  <div style={{ width: "100%", height: "100%" }}>
                    <div className="LogContent-inner" style={{ height: "100%" }}>
                      <div className="Center-container">
                        <div
                          className={width > MOBILE_WIDTH ? "Center" : "Center-mobile"}
                          style={{ position: "relative" }}
                        >
                          <div id="L2TxLogCard-header">
                            {title && (
                              <Typography className="BoldFont16" align="center">
                                {title}
                              </Typography>
                            )}

                            {rawTxLoaded !== null && (
                              <div style={width > MOBILE_WIDTH ? { top: "0px" } : { top: "-15px" }}>
                                <div id="RawTxLog-container">
                                  <button
                                    type="button"
                                    id="RawContent-button"
                                    style={{
                                      color: "rgb(0, 195, 255)",
                                    }}
                                    onClick={() => setRawContext(true)}
                                  >
                                    Raw Content
                                  </button>
                                </div>
                                <div id="RawTxLog-container">
                                  <button
                                    type="button"
                                    id="RawContent-button"
                                    style={{
                                      color: "rgb(0, 195, 255)",
                                    }}
                                    onClick={() => {
                                      setRawContextVisualiser(true);
                                      const newSearchParams = new URLSearchParams(searchParams);
                                      newSearchParams.append("visualizer", "true");
                                      setSearchParams(newSearchParams);
                                    }}
                                  >
                                    Visualize Transaction
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="L2TxLogCard-data-container">
                            <img style={{ marginTop: "10px" }} id="Block-icon" src={blockchain.icon} alt="bc" />
                            <Typography className="BoldFont16">{blockchain.networkName}</Typography>
                            <Typography className="BoldFont14" align="center">
                              Block Height #{L2Data.blockHeight}
                            </Typography>
                            <Typography className="BoldFont14" gutterBottom>
                              Execution Step #{L2Data.execStep}
                            </Typography>
                            <div
                              className={
                                width > MOBILE_WIDTH ? "L2TxLogCard-data-table" : "L2TxLogCard-data-table-mobile"
                              }
                            >
                              <div>
                                <div>
                                  <Typography className="BoldFont16" align="left">
                                    Issuer
                                  </Typography>
                                  {alias ? (
                                    <Alias alias={alias} />
                                  ) : (
                                    <Typography className="BoldFont14" align="center" gutterBottom>
                                      {L2Data.txIssuer}
                                    </Typography>
                                  )}
                                </div>
                              </div>
                              <div>
                                <Typography className="BoldFont16" align="left">
                                  Transaction ID
                                </Typography>
                                <div className="L2TxId-row">
                                  <Typography className="BoldFont14 L2TxId-container" align="center">
                                    {L2Data.txid ? (
                                      <Tooltip
                                        componentsProps={{
                                          tooltip: {
                                            sx: {
                                              width: "min-content !important",
                                              backgroundColor: "#4f4f72 !important",
                                              padding: "0px !important",
                                              marginTop: "-1px !important",
                                            },
                                          },
                                        }}
                                        disableFocusListener
                                        TransitionComponent={Fade}
                                        title={<div id="TxId-tooltip">{L2Data.txid}</div>}
                                      >
                                        <span
                                          onClick={() => {
                                            const newSearchParams = new URLSearchParams(searchParams.toString());
                                            newSearchParams.set("search", L2Data.txid);
                                            setSearchParams(newSearchParams);
                                          }}
                                          className="L2TxId-container"
                                        >
                                          {handleTxIds(L2Data.txid)}
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      "N/A"
                                    )}
                                  </Typography>
                                  <CopyIcon data={L2Data.txid} />
                                </div>
                              </div>
                            </div>

                            <L2TxLogDivider variant="middle" />

                            <div className="L2TxLogCard-data-table-mobile" key="L2TxContainer">
                              <div
                                className={
                                  width > MOBILE_WIDTH ? "L2TxLogCard-data-cell" : "L2TxLogCard-data-cell-mobile"
                                }
                              >
                                {L2Data?.txLogInfo?.map((logInfo: any, ind: number) => {
                                  if (logInfo.__typename === "TxLoadedGql")
                                    return (
                                      <div key={ind}>
                                        <TxLoadedSection
                                          data={logInfo}
                                          width={width}
                                          networkName={blockchain.networkName}
                                        />
                                        <L2TxLogDivider variant="middle" />
                                      </div>
                                    );
                                  if (logInfo.__typename === "ContactExecutedGql") {
                                    if (rawContextJsonObj.length > 0) {
                                      const element = findRawDataElement(rawContextJsonObj[index], logInfo);
                                      return (
                                        <div key={ind}>
                                          <TxContractExecutedSection
                                            data={logInfo as unknown as ContactExecutedGql}
                                            width={width}
                                            rawData={element}
                                          />
                                          <L2TxLogDivider variant="middle" />
                                        </div>
                                      );
                                    }
                                  }

                                  if (logInfo.__typename === "TxLogErrorGql")
                                    return (
                                      <div key={ind}>
                                        <TxLogErrorSection data={logInfo} width={width} />
                                        <L2TxLogDivider variant="middle" />
                                      </div>
                                    );
                                  if (logInfo.__typename === "LegacyCwebTxLoadedGql")
                                    return (
                                      <div key={ind}>
                                        <LegacyCwebTxLoadedSection
                                          data={logInfo}
                                          width={width}
                                          network={blockchain.networkName}
                                        />
                                        <L2TxLogDivider variant="middle" />
                                      </div>
                                    );
                                  return null;
                                })}
                              </div>

                              <RawContentModal open={rawContext} handleClose={handleClose} data={rawTxLoaded[index]} />

                              <RawContentVisualizerModal
                                handleClose={handleCloseVisualizer}
                                open={rawContextVisualiser}
                                network={blockchain.networkName}
                                txId={L2Data.txid}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </L2TxLogMuiCardContent>
            </L2TxLogMuiCard>
          </BlockchainDataContextProvider>
        );
      })}
    </div>
  );
};
export default memo(L2TxLogCard);
