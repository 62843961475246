import type { Claim } from "@coinweb/claims-client";
import type { ClaimsTableConfig, IIFETransformFunction, TransformedClaimData } from "../types";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import useRenderWorker from "./useRenderWorker";
import { useWorkerContext } from "../worker/WorkerContext";
import { useContractInfoContext } from "components/Contract/ContractInfoContext";

type ContractId = string;
type QueryKey = ["transformed-claims-table", Claim[], IIFETransformFunction];
type QueryResult = TransformedClaimData[];

const useTransformClaims = (config: ClaimsTableConfig, transformFunction: IIFETransformFunction, claims: Claim[]) => {
  const worker = useWorkerContext();
  const { render } = useRenderWorker(worker);
  const contractInfo = useContractInfoContext();

  let contractId: ContractId = "";
  if (contractInfo) {
    if ("instance_id" in contractInfo && "contractModuleId" in contractInfo) {
      // we have an instance
      contractId = contractInfo.instance_id;
    } else {
      // else we have a template
      contractId = contractInfo.details.contract_module;
    }
  }

  const queryKey: QueryKey = ["transformed-claims-table", claims, transformFunction];

  const queryFn: QueryFunction<QueryResult, QueryKey> = async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, claims, transformFunction] = queryKey;
    const transformedClaims = await Promise.all(
      claims.flatMap((claim) => render(transformFunction, claim, contractId))
    );
    const { type } = config.transform;

    if (type === "flatMap") {
      return transformedClaims.flat(2);
    }

    return transformedClaims.flat() as TransformedClaimData[];
  };

  return useQuery({ queryKey, queryFn, enabled: !!claims.length && !!transformFunction });
};

export default useTransformClaims;
