import { getContractClaimsTableTransformFunction } from "components/Contract/ClaimsTables/helpers";
import { getContractClaimsTablesConfig } from "components/Contract/helpers";
import { useExtractFilesFromUrl } from "hooks/useExtractFilesFromUrl";
import { TargetInstance, TransformedTemplate, useGlobalIndex } from "hooks/useGlobalIndex";
import { useMemo } from "react";

type VerifyType = "verification";
type TransformType = {
  tableIndex: number;
};

type FunctionType = VerifyType | TransformType;

export const useGetContractFunction = ({ contractId, type }: { contractId?: string; type: FunctionType }) => {
  const { isLoading: isLoadingGlobalIndex, findContractTemplate, findContractInstance } = useGlobalIndex();

  const contract = useMemo(
    () => (contractId ? findContractTemplate(contractId) || findContractInstance(contractId) : null),
    [contractId, isLoadingGlobalIndex, findContractInstance, isLoadingGlobalIndex]
  );

  const packageUrl =
    (contract as TransformedTemplate)?.details?.pkg_mirror.url ?? (contract as TargetInstance)?.packageURL ?? "";

  const { data: files } = useExtractFilesFromUrl(packageUrl);

  const contractFunction = useMemo(() => {
    if (!files) {
      return null;
    }
    const config = getContractClaimsTablesConfig(files);

    const main = type === "verification" ? config?.verification?.main : config?.tables[type.tableIndex].transform.main;
    if (!main) {
      return null;
    }
    return getContractClaimsTableTransformFunction(files, main);
  }, [contract, files, type]);

  return useMemo(() => ({ contractFunction }), [contractFunction]);
};
