import { useState } from "react";
import { type Claim } from "@coinweb/claims-client";
import type { ClaimsTableConfig, TransformedClaimData } from "../types";
import useRenderWorker from "../hooks/useRenderWorker";
import { ClaimsTableBodyStructureRenderer } from "../ClaimsTableStructureRenderer/ClaimsTableStructureRenderer";
import { useWorkerContext } from "../worker/WorkerContext";
import { Collapse, Grid2 as Grid, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LoadingBox from "components/LoadingBox";

const ClaimsTableMapRender = ({
  claim,
  config,
  transformFunction,
}: {
  config: ClaimsTableConfig;
  claim: Claim;
  transformFunction: string;
}) => {
  const worker = useWorkerContext();
  // const { data, isLoading, error } = useRenderWorker(worker, transformFunction, claim as Claim);

  const isLoading = true;
  const data = null;
  const error = null;

  const [isOpen, setIsOpen] = useState(false);

  if (isLoading) {
    return <LoadingBox />;
  }

  if (!data || error) {
    return null;
  }

  return (
    <>
      <TableRow onClick={() => setIsOpen(!isOpen)}>
        <TableCell sx={{ width: "20px", borderBottom: "none" }} style={{ cursor: "pointer" }}>
          <IconButton aria-label="expand row" size="small">
            {isOpen ? (
              <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={{ borderBottom: "none" }}>
          <Grid container>
            <Grid size={{ xs: 6 }}>
              <Typography className="BoldFont14" align="left">
                {config.alias}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>

      {(data as TransformedClaimData[]).map((prop: TransformedClaimData, index) => {
        return (
          <TableRow key={prop.type.concat(String(index))}>
            <TableCell sx={{ width: "100px", borderBottom: "none", paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
              <Collapse className="operationCollapseCell" in={isOpen} timeout="auto" unmountOnExit>
                <ClaimsTableBodyStructureRenderer structure={prop} />
              </Collapse>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default ClaimsTableMapRender;
