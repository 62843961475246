import { useState } from "react";
import { Table, TableBody, TableHead } from "@mui/material";
import type { TransformedClaimData } from "../types";
import ClaimsTableFlatMapRenderHeader from "./ClaimsTableFlatMapRenderHeader";
import ClaimsTableFlatMapRenderEntries from "./ClaimsTableFlatMapRenderEntries";
import ClaimsTableFlatMapRenderPagination from "./ClaimsTableFlatMapRenderPagination";

const ClaimsTableFlatMapRender = ({
  tableIndex,
  transformedClaims,
}: {
  tableIndex: number;
  transformedClaims: TransformedClaimData[];
}) => {
  const [flatMapPage, setFlatMapPage] = useState(0);
  const [flatMapCount, setFlatMapCount] = useState(10);
  const totalColSpan = transformedClaims[0].contents.length;

  return (
    <Table>
      <TableHead sx={{ "& .MuiTableCell-root": { borderWidth: "0" } }}>
        <ClaimsTableFlatMapRenderHeader transformedClaims={transformedClaims} />
      </TableHead>

      <TableBody sx={{ "& .MuiTableCell-root": { padding: "4px", borderWidth: "0" } }}>
        <ClaimsTableFlatMapRenderEntries
          tableIndex={tableIndex}
          transformedClaims={transformedClaims}
          page={flatMapPage}
          count={flatMapCount}
        />

        <ClaimsTableFlatMapRenderPagination
          colSpan={totalColSpan}
          transformedClaims={transformedClaims}
          page={flatMapPage}
          count={flatMapCount}
          setCount={setFlatMapCount}
          setPage={setFlatMapPage}
        />
      </TableBody>
    </Table>
  );
};

export default ClaimsTableFlatMapRender;
