import * as Comlink from "comlink";
import { useQuery } from "@tanstack/react-query";
import type { QueryFunction } from "@tanstack/react-query";
import type { IIFETransformFunction } from "../types";
import type { TransformWorker } from "../worker/WorkerContext";
import useIpfsResolver from "components/Nfts/hooks/useIpfsResolver";
import useClaimsClient from "components/Nfts/hooks/useClaimsClients";
import { Nft } from "components/Nfts/hooks/useIpfsNftResolver/types";
import useIpfsCidValidate from "components/Nfts/hooks/useIpfsCidValidate";
import translateNetworkString from "../../helper";

type QueryKey = ["validate-worker", Nft | null, IIFETransformFunction | null];
type QueryResult = (Nft & { claim: { coinwebTxId?: string; [key: string]: unknown } }) | null;

/**
 * This hook is not meant to be necessarily used inside the contract info context
 * Do not access the contract info context inside this hook
 * Pull the data from the metadata instead
 */
export default function useValidateWorker({
  worker,
  iife_module,
  metadata,
}: {
  worker: TransformWorker;
  iife_module: IIFETransformFunction | null;
  metadata:
    | (Nft & {
        params: {
          search?: string;
          [key: string]: unknown;
        };
      })
    | null;
}) {
  const { readClaims, readClaim } = useClaimsClient();
  const { load, walk } = useIpfsResolver();
  const { validateIpfsCid } = useIpfsCidValidate();

  const queryKey: QueryKey = ["validate-worker", metadata, iife_module];

  const queryFn: QueryFunction<QueryResult, QueryKey> = async ({ queryKey }) => {
    const [, metadata, iife_module] = queryKey;
    if (!metadata || !iife_module) return false;

    const contractId = metadata?.token_info?.contract_address;
    const networkName = translateNetworkString(metadata?.token_info?.coinweb_network_id)?.clientLibrary;

    const callbacks = [
      Comlink.proxy(readClaims(contractId, networkName)),
      Comlink.proxy(readClaim(contractId, networkName)),
      Comlink.proxy(load),
      Comlink.proxy(walk),
      Comlink.proxy(validateIpfsCid),
    ];

    const result = await worker.api.execute(iife_module, metadata, ...callbacks);

    return result ?? null;
  };

  const isEnabled = !!metadata && !!iife_module;

  return useQuery({ queryFn, queryKey, enabled: isEnabled });
}
