import React from "react";
import { Table, TableCell, IconButton, Typography, Collapse, TableBody, TableRow } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TargetInstances from "./TargetInstances";
import copyToClipboard from "utils/copyToClipboard";

interface RowProps {
  row: any;
  title: string;
  code?: boolean;
  isOpen: boolean;
  toggleOpen: (val: boolean) => void;
}

const Row: React.FC<RowProps> = ({ row, title, code = false, isOpen, toggleOpen }) => {
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Table style={{ maxWidth: "100%", tableLayout: "fixed" }}>
        <TableBody>
          <TableRow>
            <TableCell onClick={() => toggleOpen(!isOpen)} className="operationCollapseCell" sx={{ width: "100%" }}>
              <IconButton aria-label="expand row" size="small">
                {isOpen ? (
                  <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
                )}
              </IconButton>
              <Typography className="BoldBlueFont16">{title}</Typography>
            </TableCell>

            <TableCell sx={{ width: "100% !important" }} className="operationCollapseCell">
              <Collapse
                onClick={() => code && copyToClipboard(row)}
                in={isOpen}
                timeout="auto"
                unmountOnExit
                sx={{ width: "100% !important" }}
              >
                {code ? (
                  <div
                    id="collapse_container"
                    className="BoldFont14"
                    style={{
                      overflowY: "scroll",
                      maxHeight: "500px",
                    }}
                  >
                    <pre id="code" style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                      {row}
                    </pre>
                  </div>
                ) : (
                  <div>
                    {(row && Array.isArray(row) && row.length === 0) || !row || row === null || row === "" ? (
                      <Typography className="BoldFont14" align="center">
                        not provided
                      </Typography>
                    ) : (
                      <TargetInstances data={row} />
                    )}
                  </div>
                )}
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default Row;
