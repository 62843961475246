import "./App.css";
import "aos/dist/aos.css";
import Main from "./pages/Main";
import Redirect from "./pages/Redirect";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/tx/*" element={<Redirect />} />
      <Route path="*" element={<Main />} />
    </Routes>
  );
}

export default App;
