import { Grid2 as Grid, Skeleton } from "@mui/material";

const ClaimsTableSkeleton = ({ withHeader = false, rowsCount = 1, colsCount = 5 }) => {
  return (
    <Grid container sx={{ padding: 1 }} gap={0.5}>
      {withHeader && (
        <Grid size={{ xs: 12 }}>
          <Grid container spacing={1}>
            {Array.from(new Array(colsCount)).map((_, index) => (
              <Grid size={{ xs: (1 / colsCount) * 12 }} key={`header_${index}`}>
                <Skeleton variant="rectangular" height={25} sx={{ borderRadius: "3px" }} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      {Array.from(new Array(rowsCount)).map((_, index) => (
        <Grid key={index} size={{ xs: 12 }}>
          <Grid container spacing={1}>
            {Array.from(new Array(colsCount)).map((_, index) => (
              <Grid size={{ xs: (1 / colsCount) * 12 }} key={`content_${index}`}>
                <Skeleton variant="rectangular" height={15} sx={{ borderRadius: "3px" }} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default ClaimsTableSkeleton;
