import { useMemo, Fragment } from "react";
import type { TransformedClaimData } from "../types";
import ClaimsTableFlatMapRenderRowDivider from "./ClaimsTableFlatMapRenderRowDivider";
import { ClaimsTableBodyStructureRenderer } from "../ClaimsTableStructureRenderer";

const ClaimsTableFlatMapRenderEntries = ({
  tableIndex,
  page,
  count,
  transformedClaims,
}: {
  tableIndex: number;
  page: number;
  count: number;
  transformedClaims: TransformedClaimData[];
}) => {
  const claimsToRender = useMemo(() => {
    const from = page * count;
    const to = (page + 1) * count;
    return transformedClaims.slice(from, to);
  }, [page, count, transformedClaims]);

  const totalColSpan = transformedClaims[0].contents.length;

  return (
    <>
      {claimsToRender.map((section, index) => {
        return (
          <Fragment key={`${tableIndex}_section_${index}_${page + 1}`}>
            <ClaimsTableFlatMapRenderRowDivider colSpan={totalColSpan} />
            <ClaimsTableBodyStructureRenderer structure={section} />
          </Fragment>
        );
      })}
    </>
  );
};

export default ClaimsTableFlatMapRenderEntries;
