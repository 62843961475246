import { useState } from "react";
import { FileObject } from "hooks/useExtractFilesFromUrl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getContractClaimsTablesConfig } from "../helpers";
import { Collapse, IconButton, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import checkIfTablesVisible from "utils/checkIfTablesVisible";
import { REACT_APP_CLAIM_TABLES } from "conf";
import ClaimsTables from "../ClaimsTables";

const ContractTemplateClaimsTables = ({ files }: { files: FileObject }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const claimsTablesConfig = getContractClaimsTablesConfig(files);

  if (!claimsTablesConfig || !checkIfTablesVisible(claimsTablesConfig, true) || REACT_APP_CLAIM_TABLES !== "true") {
    return null;
  }

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className="operationCollapseCell" sx={{ width: "100%" }}>
            <div
              className="operationCollapseCell"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              style={{ cursor: "pointer" }}
            >
              <IconButton aria-label="expand row" size="small">
                {isDropdownOpen ? (
                  <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
                )}
              </IconButton>
              <Typography className="BoldBlueFont16">Tables</Typography>
            </div>
          </TableCell>
          <TableCell sx={{ width: "100% !important" }} className="operationCollapseCell">
            <Collapse in={isDropdownOpen} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
              <ClaimsTables config={claimsTablesConfig} files={files} />
            </Collapse>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
export default ContractTemplateClaimsTables;
