import "./styles.css";
import Typography from "@mui/material/Typography";
import { TargetInstance } from "hooks/useGlobalIndex/types";
import { memo } from "react";
import CopyIcon from "components/CopyIcon";
import { Fade, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import handleTxIds from "utils/handleTxIds";
type TargetInstancesProps = {
  data: TargetInstance[];
};

const TargetInstances = (props: TargetInstancesProps) => {
  const { data } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div>
      {data.map((target, index) => (
        <div
          key={index}
          style={{
            width: "100%",
            backgroundColor: "#20214a",
            borderRadius: "6px",
            marginBottom: "20px",
          }}
          className="grid_container"
          onClick={() => {
            const newSearchParams = new URLSearchParams(searchParams.toString());
            newSearchParams.set("search", target?.instance_id);
            setSearchParams(newSearchParams);
          }}
        >
          <div className="grid_cell" style={{ margin: "10px 0px" }}>
            <Typography className="BoldWhiteFont14" align="left">
              Contract Instance Alias
            </Typography>
            <div className="L2TxId-row">
              <Typography className="BoldFont14Hover" align="center">
                {target?.alias}
              </Typography>
            </div>
          </div>
          <div className="grid_cell" style={{ margin: "10px 0px" }}>
            <Typography className="BoldWhiteFont14" align="left">
              Contract Instance Id
            </Typography>
            <div className="L2TxId-row L2TxId-container">
              <Typography className="BoldFont14Hover" align="center">
                <>
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          width: "min-content !important",
                          backgroundColor: "#4f4f72 !important",
                          padding: "0px !important",
                          marginTop: "-1px !important",
                        },
                      },
                    }}
                    disableFocusListener
                    TransitionComponent={Fade}
                    title={<div id="TxId-tooltip">{target?.instance_id}</div>}
                  >
                    <span>{handleTxIds(target?.instance_id)}</span>
                  </Tooltip>
                </>
              </Typography>
              <CopyIcon data={target?.instance_id} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(TargetInstances);
