import { ClaimKeyFirst, ClaimKeySecond, cweb_api, NetworkName } from "@coinweb/claims-client";
import { REACT_APP_WALLET_API_ENDPOINT } from "conf";
import { useMemo } from "react";

const useClaimsClient = () => {
  const cwebClaimsClient = useMemo(() => cweb_api(REACT_APP_WALLET_API_ENDPOINT || ""), []);

  const initRequest = async (contractId: string, networkName: NetworkName) => {
    await cwebClaimsClient.update_tip();
    const shard = cwebClaimsClient.shard(networkName);

    let contract;
    const issuer = { FromSmartContract: contractId };
    if (typeof issuer === "object" && "FromSmartContract" in issuer!) {
      contract = shard.contract("0x".concat((issuer.FromSmartContract as string).replace(/^0x/, "")));
    } else {
      contract = shard.provider(issuer!);
    }

    return contract;
  };

  const readClaims =
    (contractId: string, shard: NetworkName) =>
    async (firstKey: ClaimKeyFirst, offset: number = 0, count: number = 10) => {
      return await initRequest(contractId, shard).then((contract) => contract.get_all(firstKey, offset, count));
    };

  const readClaim =
    (contractId: string, shard: NetworkName) => async (firstKey: ClaimKeyFirst, secondKey: ClaimKeySecond) => {
      return await initRequest(contractId, shard).then((contract) => contract.get(firstKey, secondKey));
    };

  return { readClaims, readClaim };
};

export default useClaimsClient;
