import "./L2TxLogCard.css";
import { memo } from "react";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { EmbeddedGql } from "../../generated/graphql";
import { Button, Fade, Tooltip } from "@mui/material";
import { MOBILE_WIDTH } from "components/TableContainer/CustomizedMui";
import handleTxIds from "utils/handleTxIds";
import { useBlockchainData } from "./context";
import { BlockchainExplorer } from "utils/explorerLinks";
import { toUpper } from "lodash/fp";

type L1EmbeddedParentSectionProps = {
  data: EmbeddedGql;
  width: any;
};

const L1TxId = ({ txid }: { txid: string }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const blockchainData = useBlockchainData();

  const l1ExplorerKey = blockchainData.searchQueries.map(toUpper).find((query) => {
    const upperCaseQuery = query.toUpperCase();
    return upperCaseQuery in BlockchainExplorer;
  }) as keyof typeof BlockchainExplorer | undefined;

  const l1TxUrl = (() => {
    if (l1ExplorerKey) {
      const l1Explorer = BlockchainExplorer[l1ExplorerKey];

      if (
        [
          BlockchainExplorer.BNB,
          BlockchainExplorer.BNB_TESTNET,
          BlockchainExplorer.ETHEREUM,
          BlockchainExplorer.ETHEREUM_TESTNET,
          BlockchainExplorer.POLYGON,
          BlockchainExplorer.POLYGON_TESTNET,
          BlockchainExplorer.ARBITRUM,
          BlockchainExplorer.ARBITRUM_TESTNET,
        ].includes(l1Explorer)
      ) {
        return `${l1Explorer}0x${txid}`;
      }

      return `${l1Explorer}${txid}`;
    }
  })();

  return (
    <div className="L2TxId-row">
      <Typography className="BoldFont14 L2TxId-container" align="center">
        {txid ? (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#4f4f72 !important",
                  padding: "0px !important",
                  marginTop: "-1px !important",
                },
              },
            }}
            disableFocusListener
            TransitionComponent={Fade}
            title={<div id="TxId-tooltip">{txid}</div>}
          >
            {l1TxUrl ? (
              <a
                id="TxId-container"
                className="L2TxId-container"
                href={l1TxUrl}
                target="_blank"
                rel="noreferrer noopener"
                style={{ all: "unset" }}
              >
                <Button className="L1TxExplorerButton">
                  <img src={blockchainData.icon} alt="blockchainIcon" /> {handleTxIds(txid)}
                </Button>
              </a>
            ) : (
              <span
                id="TxId-container"
                className="L2TxId-container"
                onClick={() => {
                  const newSearchParams = new URLSearchParams(searchParams.toString());
                  newSearchParams.set("search", txid);
                  setSearchParams(newSearchParams);
                }}
              >
                {handleTxIds(txid)}
              </span>
            )}
          </Tooltip>
        ) : (
          "N/A"
        )}
      </Typography>
    </div>
  );
};

const L1EmbeddedParentSection = (props: L1EmbeddedParentSectionProps) => {
  const { data, width } = props;

  return (
    <div
      className="L2txLogCardInfo-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <div id="Carousel" style={{ textIndent: "0px !important" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <div className="ParentsCarousel-inner" style={{ height: "100%" }}>
            <div className="ParentsCenter-container">
              <div>
                <Typography className="BoldFont16">Embedded at L1 Txid</Typography>

                <L1TxId txid={data.l1Txs[0]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(L1EmbeddedParentSection);
