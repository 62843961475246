import "../styles.css";
import { useState } from "react";
import { REACT_APP_CLAIM_TABLES } from "conf";
import Grid from "@mui/material/Grid2";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FileObject } from "hooks/useExtractFilesFromUrl";
import checkIfTablesVisible from "utils/checkIfTablesVisible";
import { getContractClaimsTablesConfig } from "../helpers";
import ClaimsTables from "../ClaimsTables";

const ContractInstanceClaimsTables = ({ files }: { files: FileObject }) => {
  const claimsTablesConfig = getContractClaimsTablesConfig(files);
  const [isDropdownOpen, setIsDropdownOpen] = useState(claimsTablesConfig?.tables.length === 1 || false);

  if (!claimsTablesConfig || !checkIfTablesVisible(claimsTablesConfig, false) || REACT_APP_CLAIM_TABLES !== "true") {
    return null;
  }

  return (
    <Grid container>
      <Grid size={{ xs: 12 }} sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "auto" }}>
        <div
          style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Directly invoke handleExpandChange here
        >
          <IconButton aria-label="expand row" size="small">
            {isDropdownOpen ? (
              <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
            )}
          </IconButton>
          <Typography className="BlueFont14">Tables</Typography>
        </div>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Collapse
          sx={{
            overflow: "scroll",
            borderRadius: "6px",
            backgroundColor: "#20214a",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
          in={isDropdownOpen}
          timeout="auto"
          unmountOnExit
        >
          <ClaimsTables files={files} config={claimsTablesConfig} />
        </Collapse>
      </Grid>
    </Grid>
  );
};
export default ContractInstanceClaimsTables;
