import "./styles.css";
import { useMemo } from "react";

import { L2TxLogDivider, MOBILE_WIDTH } from "components/TableContainer/CustomizedMui";
import { L2TxLogMuiCard } from "components/TableContainer/CustomizedMui";
import { useIpfsNftResolver } from "components/Nfts/hooks/useIpfsNftResolver";
import { Alias } from "shared/components";
import { useWorkerContext } from "components/Contract/ClaimsTables/worker/WorkerContext";
import useValidateWorker from "components/Contract/ClaimsTables/hooks/useValidateWorker";
import { useGetContractFunction } from "hooks/useGetContractFunction";
import useIpfsCidValidate from "./hooks/useIpfsCidValidate";
import LoadingBox from "components/LoadingBox";
import { useGlobalIndex } from "hooks/useGlobalIndex";
import { useSearchParams } from "react-router-dom";
import { Avatar, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import TxChip from "components/TxChip";
import CoinwebLogo from "assets/Icons/Networks/cweb.png";

export const NftBlock = ({ ipfs, width }: { ipfs: string; width: number }) => {
  const worker = useWorkerContext();
  const { findContractInstance } = useGlobalIndex();
  const { replaceIpfsPrefixWithHttp } = useIpfsCidValidate();
  const [searchParams] = useSearchParams();

  const {
    data: nftMetaData = null,
    isLoading: isLoadingMetadata,
    error: resolverError,
  } = useIpfsNftResolver({ ipfsPath: ipfs });

  const { contractFunction } = useGetContractFunction({
    contractId: nftMetaData?.token_info?.contract_address,
    type: "verification",
  });

  const {
    data: validNftData,
    error: validateError,
    isLoading: validateIsLoading,
    isFetched: validateIsFetched,
  } = useValidateWorker({
    worker,
    iife_module: contractFunction,
    metadata: nftMetaData ? { ...nftMetaData, params: Object.fromEntries(searchParams.entries()) } : null,
  });

  const error = resolverError || validateError;

  const contractInstanceAlias = useMemo(() => {
    return nftMetaData?.token_info?.contract_address
      ? findContractInstance(nftMetaData?.token_info?.contract_address)?.alias
      : null;
  }, [nftMetaData, findContractInstance]);

  const isLoading = isLoadingMetadata || validateIsLoading;

  if (isLoading) {
    <LoadingBox />;
  }

  if (!isLoading && validateIsFetched && (!nftMetaData || !validNftData)) {
    return <div className="invalid">Invalid NFT</div>;
  }

  if (error) {
    return error && <div className="invalid">{error.message}</div>;
  }

  return (
    <div
      className="L2txLogCard-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      {nftMetaData && (
        <L2TxLogMuiCard sx={{ minWidth: 100, padding: "20px" }}>
          <Typography className="BoldFont14">Coinweb NFT</Typography>
          <L2TxLogDivider variant="middle" />

          <Grid container spacing={4}>
            <Grid size={{ xs: 12, md: 5, lg: 4 }}>
              <img
                src={replaceIpfsPrefixWithHttp(nftMetaData.image)}
                alt={nftMetaData.name}
                crossOrigin="anonymous"
                style={{
                  width: "100%",
                  boxShadow: "0px 0px 44px -14px rgba(255, 255, 255, 0.45)",
                  WebkitBoxShadow: "0px 0px 44px -14px rgba(255, 255, 255, 0.45)",
                  MozBoxShadow: "0px 0px 44px -14px rgba(255, 255, 255, 0.45)",
                }}
              />
            </Grid>

            <Grid size={{ xs: 12, md: 7, lg: 8 }}>
              <div className="info">
                <h1>{nftMetaData.name}</h1>
                {nftMetaData.external_url && (
                  <a className="external" href={nftMetaData.external_url} target="_blank" rel="noreferrer">
                    See Homepage
                  </a>
                )}
                <span>{nftMetaData.description}</span>

                <div className="tokenInfo">
                  <span>ID:</span>
                  <span>{nftMetaData?.token_info?.token_id}</span>
                </div>

                <div className="tokenInfo">
                  <span>Contract:</span>
                  {contractInstanceAlias && (
                    <Alias
                      alias={contractInstanceAlias as string}
                      chain={nftMetaData?.token_info?.coinweb_network_id}
                    />
                  )}
                </div>

                {validNftData?.claim?.coinwebTxId && (
                  <div className="tokenInfo">
                    <span>Transaction:</span>
                    <TxChip
                      component="a"
                      icon={<Avatar src={CoinwebLogo} />}
                      tx={validNftData?.claim?.coinwebTxId}
                      href={`/?search=${validNftData?.claim?.coinwebTxId}`}
                    />
                  </div>
                )}

                <span>Attributes</span>
                <Grid container spacing={2}>
                  {nftMetaData?.attributes
                    .filter((attr) => !!attr.value)
                    .map((attr, idx) => (
                      <Grid key={`nft_attr_${idx}`} size={"auto"}>
                        <div className="attribute">
                          <span className="name">{attr.trait_type}</span>
                          <span className="value">{attr.value}</span>
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </L2TxLogMuiCard>
      )}
    </div>
  );
};
