import { REACT_APP_API_ENDPOINT } from "conf";
import { request } from "graphql-request";
import { QUERY_BLOCK_GQL } from "../components/queries/block";
import { QUERY_L2_TX_FULL_LOG_GQL } from "../components/queries/l2TxFullLog";

export const getBlock = async (hash: String | undefined, network: any) => {
  if (REACT_APP_API_ENDPOINT) {
    const res = await request(REACT_APP_API_ENDPOINT, QUERY_BLOCK_GQL, {
      hash,
      network,
    });
    return res;
  }
};

export const getL2Txs = async (txid: String, network: any) => {
  if (REACT_APP_API_ENDPOINT) {
    const res = await request(REACT_APP_API_ENDPOINT, QUERY_L2_TX_FULL_LOG_GQL, {
      txid,
      network,
    });
    return res;
  }
};
