import { REACT_APP_DEVNET } from "conf";
import { BlockchainExplorer } from "utils/explorerLinks";

export default function useExplorerResolver() {
  const replaceExplorerPrefixWithHttp = (path: string): string => {
    const devnet = REACT_APP_DEVNET === "true";
    const explorerUrl = devnet ? BlockchainExplorer.COINWEB_DEVNET : BlockchainExplorer.COINWEB;
    const explorerUrlClean = explorerUrl.replace(/(coinweb\.io\/).*/, "coinweb.io/");

    return path.replace(/^explorer:\/\//, explorerUrlClean);
  };

  return { replaceExplorerPrefixWithHttp };
}
