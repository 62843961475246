import { Typography } from "@mui/material";
import TxChip from "components/TxChip";
import useIpfsCidValidate from "components/Nfts/hooks/useIpfsCidValidate";
import useExplorerResolver from "../hooks/useExplorerResolver";
import { TransformedClaimDataLink } from "../types";

const StructureRendererLink: React.FC<{ data: TransformedClaimDataLink }> = ({ data }) => {
  const { replaceIpfsPrefixWithHttp } = useIpfsCidValidate();
  const { replaceExplorerPrefixWithHttp } = useExplorerResolver();

  const href = data.href.startsWith("ipfs://")
    ? replaceIpfsPrefixWithHttp(data.href)
    : data.href.startsWith("explorer://")
    ? replaceExplorerPrefixWithHttp(data.href)
    : data.href;

  return (
    <TxChip
      withTooltip={false}
      component="a"
      href={href}
      label={
        <Typography align="center" className="BoldFont14">
          {data.anchor}
        </Typography>
      }
    />
  );
};

export default StructureRendererLink;
