import React, { createContext, useContext, type ReactNode } from "react";
import { TargetInstance, TransformedTemplate } from "hooks/useGlobalIndex";

type ContractInfoContextType = TransformedTemplate | TargetInstance | null;

const ContractInfoContext = createContext<ContractInfoContextType>(null);

export const ContractInfoProvider: React.FC<{ contractInfo: ContractInfoContextType; children: ReactNode }> = ({
  contractInfo,
  children,
}) => {
  return <ContractInfoContext.Provider value={contractInfo}>{children}</ContractInfoContext.Provider>;
};

export const useContractInfoContext = (): ContractInfoContextType => {
  const context = useContext(ContractInfoContext);
  if (!context) {
    throw new Error("useContractInfoContext must be used within a ContractInfoProvider");
  }
  return context;
};
