import { type Claim } from "@coinweb/claims-client";
import ClaimsTableMapRender from "./ClaimsTableMapRender";
import { ClaimsTableConfig } from "../types";
import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";

const ClaimsTableMap = ({
  claims,
  config,
  transformFunction,
}: {
  config: ClaimsTableConfig;
  claims: Claim[];
  transformFunction: string;
}) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell style={{ borderBottom: "none", paddingBottom: "5px" }} colSpan={2}>
            <Typography className="BoldFont16">{config.name}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ borderBottom: "none", paddingBottom: "5px" }} colSpan={2}>
            <Typography className="BoldFont14">{config.description}</Typography>
          </TableCell>
        </TableRow>
        {claims.map((claim) => {
          return (
            <ClaimsTableMapRender
              key={JSON.stringify(claim)}
              config={config}
              claim={claim}
              transformFunction={transformFunction}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ClaimsTableMap;
