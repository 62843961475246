import { NetworkName } from "generated/graphql";
import handleTxIds from "./handleTxIds";
import { BlockchainExplorer } from "./explorerLinks";
import { GLOBAL_INDEX_WINDOW_SYMBOL } from "conf";

const DEFAULT_PARAMS: ProcessTxIdProperties = {
  type: "l1",
  shouldSlice: false,
  shortIfAlias: true,
};

export type ProcessTxIdProperties = {
  type?: "l1" | "l2";
  shouldSlice?: boolean;
  shortIfAlias?: boolean;
} & (
  | {
      network?: NetworkName;
    }
  | { network: never }
);

type ProcessTxIdPropertiesWithNetwork = {
  type?: "l1" | "l2";
  shouldSlice?: boolean;
  network?: NetworkName;
  shortIfAlias?: boolean;
};

type ProcessTxIdPropertiesWithoutNetwork = {
  type?: "l1" | "l2";
  shouldSlice?: boolean;
  network?: never;
  shortIfAlias?: boolean;
};

const zeroXChains = [
  NetworkName.Arbitrum,
  NetworkName.ArbitrumTestnet,
  NetworkName.Bnb,
  NetworkName.BnbTestnet,
  NetworkName.Ethereum,
  NetworkName.EthereumTestnet,
  NetworkName.Polygon,
  NetworkName.PolygonTestnet,
];

export function processTxId(txId?: string, params?: ProcessTxIdPropertiesWithoutNetwork): string;
export function processTxId(
  txId?: string,
  params?: ProcessTxIdPropertiesWithNetwork
): { text: string; link?: string; initial?: string };
export function processTxId(
  txId?: string,
  params?: ProcessTxIdProperties
): { text: string; link?: string; initial?: string } | string {
  const { type, network, shouldSlice, shortIfAlias } = params ?? DEFAULT_PARAMS;

  let result = txId ?? "";

  if (type === "l2") {
    if (result.startsWith("0x")) result = result.substring(2);
  } else {
    if (zeroXChains.includes(network as NetworkName) && !result.startsWith("0x")) {
      result = `0x${result}`;
    }
  }

  const alias = shortIfAlias && findAlias(result);

  if (network) {
    const link = NetworkNameToBlockchainExplorerLink[network];
    const text = shouldSlice ? handleTxIds(result) : result;

    return {
      text: alias || text,
      link: link ? `${link}${result}` : undefined,
      initial: result,
    };
  }

  if (alias) {
    return alias;
  }

  if (shouldSlice) {
    return handleTxIds(result);
  }

  return result;
}

export const findAlias = (id: string) => {
  const aliasesMap = window[GLOBAL_INDEX_WINDOW_SYMBOL];
  if (!aliasesMap || !id) return;

  const interpreter = aliasesMap.interpreters?.[id]?.alias;
  const contractInstance = aliasesMap.contractInstances?.[id]?.alias;
  const contractTemplate = aliasesMap.contractTemplates?.[id]?.alias;

  const alias = interpreter ?? contractInstance ?? contractTemplate ?? undefined;

  return alias ? `${alias}(${handleTxIds(id)})` : undefined;
};

export const NetworkNameToBlockchainExplorerLink: Record<NetworkName, BlockchainExplorer> = {
  [NetworkName.Arbitrum]: BlockchainExplorer.ARBITRUM,
  [NetworkName.ArbitrumTestnet]: BlockchainExplorer.ARBITRUM_TESTNET,
  [NetworkName.Bitcoin]: BlockchainExplorer.BITCOIN,
  [NetworkName.BitcoinCash]: BlockchainExplorer.BITCOIN_CASH,
  [NetworkName.BitcoinTestnet]: BlockchainExplorer.BITCOIN_TESTNET,
  [NetworkName.BitcoinCashTestnet]: BlockchainExplorer.BITCOIN_CASH_TESTNET,
  [NetworkName.Bnb]: BlockchainExplorer.BNB,
  [NetworkName.BnbTestnet]: BlockchainExplorer.BNB_TESTNET,
  [NetworkName.DevnetL1A]: BlockchainExplorer.DEVNET_L1A,
  [NetworkName.DevnetL1B]: BlockchainExplorer.DEVNET_L1B,
  [NetworkName.Dogecoin]: BlockchainExplorer.DOGECOIN,
  [NetworkName.DogecoinTestnet]: BlockchainExplorer.DOGECOIN_TESTNET,
  [NetworkName.Elrond]: BlockchainExplorer.ELROND,
  [NetworkName.ElrondTestnet]: BlockchainExplorer.ELROND_TESTNET,
  [NetworkName.Ethereum]: BlockchainExplorer.ETHEREUM,
  [NetworkName.EthereumTestnet]: BlockchainExplorer.ETHEREUM_TESTNET,
  [NetworkName.Kuji]: BlockchainExplorer.KUJI,
  [NetworkName.KujiTestnet]: BlockchainExplorer.KUJI_TESTNET,
  [NetworkName.Litecoin]: BlockchainExplorer.LITECOIN,
  [NetworkName.LitecoinTestnet]: BlockchainExplorer.LITECOIN_TESTNET,
  [NetworkName.Polygon]: BlockchainExplorer.POLYGON,
  [NetworkName.PolygonTestnet]: BlockchainExplorer.POLYGON_TESTNET,
};
