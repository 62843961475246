import "../styles.css";
import Typography from "@mui/material/Typography";
import CopyIcon from "components/CopyIcon";
import { Fade, Grid, TableRow, Tooltip } from "@mui/material";
import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import { isHexHash } from "utils/isHexHash";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { formatKey } from "utils/formatKey";
import handleTxIds from "utils/handleTxIds";

const RenderArbitraryParameter = ({ value }: { value: unknown }) => {
  return isHexHash(String(value)) ? (
    <div className="L2TxId-row">
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              width: "min-content !important",
              backgroundColor: "#4f4f72 !important",
              padding: "0px !important",
              marginTop: "-1px !important",
            },
          },
        }}
        disableFocusListener
        TransitionComponent={Fade}
        title={<div id="TxId-tooltip">{String(value)}</div>}
      >
        <div className="L2TxId-container">
          <Typography className="BoldFont14" align="center" sx={{ marginLeft: "3px" }}>
            {handleTxIds(String(value))}
          </Typography>
        </div>
      </Tooltip>
      <CopyIcon data={String(value)} />
    </div>
  ) : String(value) && String(value).length > 20 ? (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            width: "min-content !important",
            backgroundColor: "#4f4f72 !important",
            padding: "0px !important",
            marginTop: "-1px !important",
          },
        },
      }}
      disableFocusListener
      TransitionComponent={Fade}
      title={<div id="TxId-tooltip">{String(value)}</div>}
    >
      <Typography className="BoldFont14" align="center">
        {String(value).substring(0, 20) + "..."}
      </Typography>
    </Tooltip>
  ) : (
    <Typography className="BoldFont14" align="center">
      {String(value)}
    </Typography>
  );
};

export const RenderParameters = ({ parameters }: { parameters: any }) => {
  return (
    <>
      {Object.entries(parameters)
        .slice(0, 50) // Limit the number of parameters to render
        .map((param: any) => {
          const [key, value] = param;
          if (typeof value === "object" && !Array.isArray(value)) {
            return (
              <Grid key={key}>
                <Typography className="BoldBlueFont16" align="left" sx={{ marginBottom: "-5px !important" }}>
                  {formatKey(key)}
                </Typography>
                <RenderParameters parameters={value} /> {/* Recursive call */}
              </Grid>
            );
          } else {
            return (
              <Grid key={key}>
                <Typography className="BoldFont16" align="left">
                  {formatKey(key)}
                </Typography>
                <div className="L2TxId-row">
                  {Array.isArray(value) ? value.join(", ") : <RenderArbitraryParameter value={value} />}
                </div>
              </Grid>
            );
          }
        })}
    </>
  );
};

const ContractInstancesParameters = ({ parameters }: any) => {
  const [parameterOpen, setParameterOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell className="operationCollapseCell" sx={{ width: "100%" }}>
          <div
            className="operationCollapseCell"
            onClick={() => setParameterOpen(!parameterOpen)} // Directly invoke handleExpandChange here
            style={{ cursor: "pointer" }} // Add cursor pointer for better UX
          >
            <IconButton aria-label="expand row" size="small">
              {parameterOpen ? (
                <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
              )}
            </IconButton>
            <Typography className="BlueFont14">Parameters</Typography>
          </div>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ width: "100% !important" }} className="operationCollapseCell">
          <Collapse in={parameterOpen} timeout="auto" unmountOnExit>
            <Grid container gap={4}>
              <RenderParameters parameters={parameters?.content} />
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default ContractInstancesParameters;
