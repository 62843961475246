import { Box, TableCell, TablePagination, TableRow, useTheme } from "@mui/material";
import type { TransformedClaimData } from "../types";
import ClaimsTableFlatMapRenderRowDivider from "./ClaimsTableFlatMapRenderRowDivider";

const ClaimsTableFlatMapRenderPaginationNav = ({
  colSpan,
  page,
  count,
  setCount,
  setPage,
  transformedClaims,
}: {
  colSpan: number;
  page: number;
  count: number;
  setCount: (count: number) => void;
  setPage: (page: number) => void;
  transformedClaims: TransformedClaimData[];
}) => {
  const theme = useTheme();

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    event?.stopPropagation();
    setPage(newPage);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event?.stopPropagation();
    setCount(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <ClaimsTableFlatMapRenderRowDivider colSpan={colSpan} />
      <TableRow>
        <TableCell colSpan={colSpan} style={{ borderWidth: 0 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TablePagination
              component="div"
              count={transformedClaims.length}
              page={page}
              rowsPerPage={count}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              sx={{
                "& .MuiTablePagination-root": {
                  color: theme.palette.text.primary,
                },
                "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.background.default,
                },
                "& .MuiTablePagination-actions": {
                  color: theme.palette.text.primary,
                },
              }}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ClaimsTableFlatMapRenderPaginationNav;
