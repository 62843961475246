import { TableCell, TableRow, Typography } from "@mui/material";
import type {
  TransformedClaimData,
  TransformedClaimDataField,
  TransformedClaimDataHeading,
  TransformedClaimDataImage,
  TransformedClaimDataLink,
  TransformedClaimDataSection,
} from "../types";
import { truncateString } from "../helpers";
import StructureRendererImage from "./StructureRendererImage";
import StructureRendererSection from "./StructureRendererSection";
import StructureRendererLink from "./StructureRendererLink";
import StructureRendererField from "./StructureRendererField";
import StructureRendererHeading from "./StructureRendererHeading";
import { forwardRef } from "react";

type Props = { structure: TransformedClaimData };

type RenderItemsProps = {
  data:
    | TransformedClaimDataSection
    | TransformedClaimDataHeading
    | TransformedClaimDataLink
    | TransformedClaimDataField
    | TransformedClaimDataImage;
};

export const RenderItem: React.FC<RenderItemsProps> = forwardRef<HTMLElement, RenderItemsProps>(({ data }, ref) => {
  switch (data.type) {
    case "field":
      return <StructureRendererField data={data} />;
    case "heading":
      return <StructureRendererHeading data={data} />;
    case "link":
      return <StructureRendererLink data={data} />;
    case "image":
      return <StructureRendererImage data={data} />;
    case "section":
      return <StructureRendererSection data={data} />;
    default:
      return null;
  }
});

export const ClaimsTableBodyStructureRenderer = ({ structure }: Props) => {
  return <RenderItem data={structure} />;
};

export const ClaimsTableHeaderStructureRenderer = ({ structure }: Props) => {
  const RenderItemHeader = ({
    data,
  }: {
    data:
      | TransformedClaimDataSection
      | TransformedClaimDataHeading
      | TransformedClaimDataLink
      | TransformedClaimDataField
      | TransformedClaimDataImage;
  }) => {
    switch (data.type) {
      case "field":
        return <span>{truncateString(data.name, 10)}</span>;
      case "heading":
        return <span>{truncateString(data.contents, 10)}</span>;
      case "link":
        return <span>Link</span>;
      case "image":
        return <span>Image</span>;
      default:
        return null;
    }
  };

  return (
    <TableRow>
      {structure.contents.map((item, index) => {
        return (
          <TableCell key={`header-item-${index}`}>
            <Typography align="center" className="BoldFont14">
              <RenderItemHeader data={item} />
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
};
