import { useMemo } from "react";
import { REACT_APP_WALLET_API_ENDPOINT } from "conf";
import { type ClaimsTableConfig } from "../types";
import { type Claim, type ClaimIssuer, cweb_api, NetworkName } from "@coinweb/claims-client";
import { type QueryFunction, useQuery } from "@tanstack/react-query";
import useChainQueryParameters from "hooks/useChainQueryParameters";
import { useContractInfoContext } from "components/Contract/ContractInfoContext";

type QueryKey = ["claims-tables-claims", unknown, ClaimIssuer | null, keyof typeof NetworkName, Pagination];

type Pagination = { offset: number; count: number };

function useFetchClaimsTable(config: ClaimsTableConfig, pagination: Pagination = { offset: 0, count: 20 }) {
  const contractInfo = useContractInfoContext();
  const cwebClaimsClient = useMemo(() => cweb_api(REACT_APP_WALLET_API_ENDPOINT || ""), []);
  const chain = useChainQueryParameters();
  const { clientNetworkName } = chain || {};
  const { first_key } = config.claim;

  let issuer: ClaimIssuer | null = null;
  if (contractInfo) {
    if ("instance_id" in contractInfo && "contractModuleId" in contractInfo) {
      // we have an instance
      issuer = { FromSmartContract: contractInfo.instance_id };
    } else {
      // else we have a template
      issuer = { FromSmartContract: contractInfo.details.contract_module };
    }
  }

  const queryKey: QueryKey = ["claims-tables-claims", first_key, issuer, clientNetworkName, pagination];

  const queryFn: QueryFunction<Claim[], QueryKey> = async ({ queryKey }) => {
    const [, first_key, issuer, networkName] = queryKey;
    const { offset, count } = pagination;

    await cwebClaimsClient.update_tip();
    const networkToFetchFrom = NetworkName[networkName];
    const shard = cwebClaimsClient.shard(networkToFetchFrom);

    let contract;
    if (typeof issuer === "object" && "FromSmartContract" in issuer!) {
      contract = shard.contract("0x".concat((issuer.FromSmartContract as string).replace(/^0x/, "")));
    } else {
      contract = shard.provider(issuer!);
    }

    const claims = await contract.get_all(first_key, offset, count);

    return claims;
  };

  const isEnabled = !!first_key && !!issuer && !!clientNetworkName;

  return useQuery({ queryKey, queryFn, enabled: isEnabled });
}

export default useFetchClaimsTable;
