import { Box, Button, TableCell, TableRow } from "@mui/material";
import type { TransformedClaimData } from "../types";
import ClaimsTableFlatMapRenderRowDivider from "./ClaimsTableFlatMapRenderRowDivider";
import ClaimsTableSkeleton from "../ClaimsTableSkeleton";
import useClaimsStore from "../store";

const ClaimsTableFlatMapRenderPaginationLoadMore = ({
  colSpan,
  page,
  count,
  transformedClaims,
}: {
  colSpan: number;
  page: number;
  count: number;
  transformedClaims: TransformedClaimData[];
}) => {
  const {
    isLoading,
    isLastRange,
    count: claimsCount,
    offset: claimsOffset,
    setOffset: setClaimsOffset,
  } = useClaimsStore();

  const isLastPage = transformedClaims.length <= count * (page + 1);

  if (isLoading) {
    return (
      <>
        <ClaimsTableFlatMapRenderRowDivider colSpan={colSpan} />
        <TableRow>
          <TableCell colSpan={colSpan}>
            <ClaimsTableSkeleton rowsCount={1} colsCount={colSpan} />
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <>
      {!isLastRange && isLastPage && (
        <>
          <ClaimsTableFlatMapRenderRowDivider colSpan={colSpan} />
          <TableRow>
            <TableCell colSpan={colSpan}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    setClaimsOffset(claimsOffset + claimsCount);
                  }}
                >
                  Load More
                </Button>
              </Box>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

export default ClaimsTableFlatMapRenderPaginationLoadMore;
