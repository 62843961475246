import { useState } from "react";
import { FileObject } from "hooks/useExtractFilesFromUrl";
import { getContractTemplateOnchainData } from "../helpers";
import Row from "../Row";

const ContractTemplateOnchain = ({ files }: { files: FileObject }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const onchainData = getContractTemplateOnchainData(files);

  if (!onchainData) {
    return null;
  }

  return (
    <Row
      code
      row={onchainData}
      title="Smart Contract Onchain Code"
      isOpen={isDropdownOpen}
      toggleOpen={setIsDropdownOpen}
    />
  );
};
export default ContractTemplateOnchain;
