import { Box, Fade, TableCell, TableRow } from "@mui/material";
import { TransformedClaimDataSection } from "../types";
import { RenderItem } from "./ClaimsTableStructureRenderer";

interface StructureRendererSectionProps extends React.HTMLAttributes<HTMLTableRowElement> {
  data: TransformedClaimDataSection;
}

const StructureRendererSection: React.FC<StructureRendererSectionProps> = ({ data }) => {
  return (
    <TableRow>
      {data.contents.map((item, index) => {
        return (
          <Fade in key={index} timeout={{ enter: 150, exit: 150 }} style={{ transitionDelay: `${index * 10}ms` }}>
            <TableCell sx={{ borderWidth: 0 }}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <RenderItem data={item} />
              </Box>
            </TableCell>
          </Fade>
        );
      })}
    </TableRow>
  );
};

export default StructureRendererSection;
