import { createTheme, unstable_createBreakpoints as createBreakpoints, Breakpoint } from "@mui/material/styles";

const BREAKPOINTS = {
  xs: 0, // Extra small devices (phones)
  sm: 576, // Small devices (large phones)
  md: 768, // Medium devices (tablets)
  lg: 992, // Large devices (desktops)
  xl: 1200, // Extra large devices (large desktops)
};

const breakpointsFull = createBreakpoints({
  keys: Object.keys(BREAKPOINTS) as Breakpoint[],
  values: BREAKPOINTS,
});

export const theme = createTheme({
  breakpoints: breakpointsFull,
  palette: {
    text: {
      primary: "#a1a1ca",
    },
    background: {
      default: "#20214d",
    },
  },
  components: {
    /**  MUI SelectMenu Paper Styling for mobile screen **/
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#37385f !important",
    //       color: "#d3d3ef !important",
    //       right: "20px!important",
    //       left: "20px!important",
    //       width: "calc(100% - 20px) !important",
    //       top: "49px !important",
    //       boxShadow: "none !important",
    //       height: "auto",
    //       bottom: "48px !important",
    //       borderRadius: "6px !important",
    //     },
    //   },
    // },
    /**  MUI SelectMenu Paper Styling for mobile screen **/
    // MuiMenu: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "#1f1d47 !important",
    //     },
    //   },
    // },
    /**  MUI Select Menu u-List Styling for mobile screen **/
    // MuiList: {
    //   styleOverrides: {
    //     root: {
    //       marginTop: "4px !important",
    //     },
    //   },
    // },
    /**  MUI Select MenuItem Styling for mobile screen **/
    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "transparent !important",
    //       height: "64px !important",
    //     },
    //   },
    // },
  },
});
