import * as Comlink from "comlink";
import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import type { QueryFunction } from "@tanstack/react-query";
import { NetworkName, type Claim } from "@coinweb/claims-client";
import type { IIFETransformFunction, TransformedClaimData } from "../types";
import { type TransformWorker } from "../worker/WorkerContext";
import useClaimsClient from "components/Nfts/hooks/useClaimsClients";
import useIpfsResolver from "components/Nfts/hooks/useIpfsResolver";
import useIpfsCidValidate from "components/Nfts/hooks/useIpfsCidValidate";
import useChainQueryParameters from "hooks/useChainQueryParameters";

type ContractId = string;
type QueryKey = ["claims-table", ContractId, Claim, IIFETransformFunction];
type QueryResult = (TransformedClaimData | TransformedClaimData[])[];

export default function useRenderWorker(worker: TransformWorker) {
  const { readClaims, readClaim } = useClaimsClient();
  const { load, walk } = useIpfsResolver();
  const { validateIpfsCid } = useIpfsCidValidate();
  const chain = useChainQueryParameters();
  const queryClient = useQueryClient();

  const render = useCallback(
    async (iife_module: IIFETransformFunction, claim: Claim, contractId: ContractId) => {
      const queryKey: QueryKey = ["claims-table", contractId, claim, iife_module];

      const queryFn: QueryFunction<QueryResult, QueryKey> = async ({ queryKey }) => {
        const [, contractId, claim, iife_module] = queryKey;
        const callbacks = [
          Comlink.proxy(readClaims(contractId, NetworkName[chain.clientNetworkName])),
          Comlink.proxy(readClaim(contractId, NetworkName[chain.clientNetworkName])),
          Comlink.proxy(load),
          Comlink.proxy(walk),
          Comlink.proxy(validateIpfsCid),
        ];

        const result = await worker.api.execute(iife_module, claim, ...callbacks);

        return result;
      };

      return queryClient.fetchQuery({ queryKey, queryFn });
    },
    [chain.clientNetworkName, load, queryClient, readClaim, readClaims, validateIpfsCid, walk, worker.api]
  );

  return { render };
}
