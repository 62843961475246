import "./L2TxLogCard.css";
import { memo } from "react";
import Typography from "@mui/material/Typography";
import { Fade, Tooltip } from "@mui/material";
import CopyIcon from "components/CopyIcon";
import { useSearchParams } from "react-router-dom";
import { TxLoadedGql, ContractGql } from "../../generated/graphql";
import L1EmbeddedParentSection from "./L1EmbeddedParentSection";
import ActiveSwipe from "../../assets/Icons/icon-swipe-active.svg";
import { MOBILE_WIDTH } from "components/TableContainer/CustomizedMui";
import handleTxIds from "utils/handleTxIds";
type TxLoadedSectionProps = {
  data: TxLoadedGql;
  width: any;
  networkName: string;
};

const TxLoadedSection = (props: TxLoadedSectionProps) => {
  const { data, width } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div
      className="L2txLogCardInfo-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <div className="relativeCon ">
        {(data?.parents as ContractGql)?.parents &&
          (data?.parents as ContractGql)?.parents[0] &&
          (data?.parents as ContractGql)?.parents[0]?.l2Txid && (
            <div
              className="ParentsCarousel-icon"
              style={{
                display: "flex",
                justifyContent: "left",
                textAlignLast: "left",
              }}
            >
              <img className="linkArrow" src={ActiveSwipe} alt="swipe" />

              <Typography
                className="TxLogFontBold14 linkHover"
                sx={{ top: "-15px", width: "auto !important" }}
                onClick={() => {
                  const newSearchParams = new URLSearchParams(searchParams.toString());
                  newSearchParams.set("search", (data.parents as ContractGql).parents[0].l2Txid);
                  setSearchParams(newSearchParams);
                }}
              >
                Parent Tx
              </Typography>
              <div className="L2TxId-row" style={{ marginLeft: "10px", display: "flex" }}>
                <Typography
                  className="BoldFont14 L2TxId-container"
                  align="center"
                  onClick={() => {
                    const newSearchParams = new URLSearchParams(searchParams.toString());
                    newSearchParams.set("search", (data.parents as ContractGql).parents[0].l2Txid);
                    setSearchParams(newSearchParams);
                  }}
                >
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "#4f4f72 !important",
                          padding: "0px !important",
                          marginTop: "-1px !important",
                        },
                      },
                    }}
                    disableFocusListener
                    TransitionComponent={Fade}
                    title={<div id="TxId-tooltip">{(data.parents as ContractGql).parents[0].l2Txid}</div>}
                  >
                    <span className="L2TxId-container" id="TxId-container">
                      {handleTxIds((data.parents as ContractGql).parents[0].l2Txid)}
                    </span>
                  </Tooltip>
                </Typography>
                <CopyIcon data={(data.parents as ContractGql).parents[0].l2Txid} />
              </div>
            </div>
          )}
      </div>

      {data.parents.__typename === "EmbeddedGql" && <L1EmbeddedParentSection data={data.parents} width={width} />}
    </div>
  );
};
export default memo(TxLoadedSection);
