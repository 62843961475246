import Typography from "@mui/material/Typography";
import CopyIcon from "components/CopyIcon";
import { Box, Fade, Grid2 as Grid, Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { TargetInstance } from "hooks/useGlobalIndex/types";
import checkIssuerAlias from "utils/checkIssuerAlias";
import { Alias } from "shared/components";
import { useGlobalIndex } from "hooks/useGlobalIndex";
import handleTxIds from "utils/handleTxIds";

type Props = {
  instance: TargetInstance;
};

const ContractInstanceDetails = ({ instance }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { contractTemplates, interpreters, contractInstances } = useGlobalIndex();

  const [templateAlias, setTemplateAlias] = useState<string>();
  const [instanceAlias, setInstanceAlias] = useState<string>(instance.alias);

  useEffect(() => {
    const templateAliasRes = checkIssuerAlias(
      instance?.contractModuleId,
      interpreters,
      contractTemplates,
      contractInstances
    );
    if (templateAliasRes) setTemplateAlias(templateAliasRes);
    const instanceAliasRes = checkIssuerAlias(
      instance?.instance_id,
      interpreters,
      contractTemplates,
      contractInstances
    );
    if (instanceAliasRes) setInstanceAlias(instanceAliasRes);
  }, [instance?.contractModuleId, instance?.instance_id, interpreters, contractTemplates, contractInstances]);

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, md: 6 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            onClick={() => {
              const newSearchParams = new URLSearchParams(searchParams.toString());
              newSearchParams.set("search", instance?.contractModuleId);
              setSearchParams(newSearchParams);
            }}
          >
            <Typography className="BoldFont16" align="left">
              Contract Module Id
            </Typography>
          </div>

          <div>
            <Tooltip
              slots={{ transition: Fade }}
              slotProps={{
                tooltip: {
                  sx: {
                    width: "min-content !important",
                    backgroundColor: "#4f4f72 !important",
                    padding: "0px !important",
                    marginTop: "-1px !important",
                  },
                },
              }}
              disableFocusListener
              title={<div id="TxId-tooltip">{instance?.contractModuleId}</div>}
            >
              {templateAlias ? (
                <Alias alias={templateAlias} />
              ) : (
                <Typography className="BoldFont14Hover" align="center">
                  {handleTxIds(instance?.contractModuleId)}
                </Typography>
              )}
            </Tooltip>
          </div>
        </Box>
      </Grid>

      <Grid size={{ xs: 12, md: 6 }}>
        <div>
          <Typography className="BoldFont16" align="left">
            Contract Instance Id
          </Typography>
        </div>
        <div>
          <Tooltip
            slots={{ transition: Fade }}
            slotProps={{
              tooltip: {
                sx: {
                  width: "min-content !important",
                  backgroundColor: "#4f4f72 !important",
                  padding: "0px !important",
                  marginTop: "-1px !important",
                },
              },
            }}
            disableFocusListener
            title={<div id="TxId-tooltip">{instance?.instance_id}</div>}
          >
            {instanceAlias ? (
              <Alias alias={instanceAlias} />
            ) : (
              <Typography className="BoldFont14" align="center">
                <div className="L2TxId-container">{handleTxIds(instance?.instance_id)}</div>
              </Typography>
            )}
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  );
};
export default ContractInstanceDetails;
