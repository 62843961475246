import { useState } from "react";
import Row from "../Row";
import { TransformedTemplate } from "hooks/useGlobalIndex";

const ContractTemplateInstances = ({ contractInfo }: { contractInfo: TransformedTemplate }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  if (!contractInfo?.details?.target_instances) {
    return null;
  }

  return (
    <Row
      row={contractInfo.details.target_instances}
      title="Known Instances"
      isOpen={isDropdownOpen}
      toggleOpen={setIsDropdownOpen}
    />
  );
};
export default ContractTemplateInstances;
