import Typography from "@mui/material/Typography";
import { FileObject } from "hooks/useExtractFilesFromUrl";

import { getContractTemplateDetails } from "../helpers";
import { Fade, Tooltip } from "@mui/material";
import handleTxIds from "utils/handleTxIds";

const ContractTemplateDetails = ({ files }: { files: FileObject }) => {
  const contractDetails = getContractTemplateDetails(files);

  if (!contractDetails) {
    return null;
  }

  const { engine, description, keywords, homepage } = contractDetails;

  return (
    <>
      <div>
        <Typography className="BoldFont16" align="left">
          Description
        </Typography>
        <div className="L2TxId-row">
          <Typography className="BoldFont14" align="center">
            {description !== null ? description : "not provided"}
          </Typography>
        </div>
      </div>
      <div>
        <Typography className="BoldFont16" align="left">
          Keywords
        </Typography>
        <div className="L2TxId-row">
          <Typography className="BoldFont14" align="center">
            {keywords !== null ? keywords : "not provided"}
          </Typography>
        </div>
      </div>
      <div>
        <Typography className="BoldFont16" align="left">
          Homepage
        </Typography>
        <div className="L2TxId-row">
          <Typography className="BoldFont14" align="center">
            {homepage !== null ? (
              <a className="BoldFont14" href={homepage} target="_blank" rel="noreferrer noopener">
                {homepage}
              </a>
            ) : (
              "not provided"
            )}
          </Typography>
        </div>
      </div>
      <div>
        <Typography className="BoldFont16" align="left">
          Engines
        </Typography>
        <div className="L2TxId-row L2TxId-container">
          <Typography className="BoldFont14" align="center">
            {engine ? (
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      width: "min-content !important",
                      backgroundColor: "#4f4f72 !important",
                      padding: "0px !important",
                      marginTop: "-1px !important",
                    },
                  },
                }}
                disableFocusListener
                TransitionComponent={Fade}
                title={<div id="TxId-tooltip">{engine}</div>}
              >
                <span>{handleTxIds(engine)}</span>
              </Tooltip>
            ) : (
              "not provided"
            )}
          </Typography>
        </div>
      </div>
    </>
  );
};
export default ContractTemplateDetails;
