import { Nft } from "./types";
import { UseResolveNftParams } from "./types";
import { useQuery, QueryFunction } from "@tanstack/react-query";
import useIpfsResolver from "../useIpfsResolver";
import useIpfsCidValidate from "../useIpfsCidValidate";

type QueryKey = ["IPFS_NFT_RESOLVER", string];
type QueryResult = (Nft & { coinwebTxId?: string }) | null;

export const useIpfsNftResolver = ({ ipfsPath }: UseResolveNftParams) => {
  const { scrapeCidFromString, validateIpfsCid } = useIpfsCidValidate();

  const { load } = useIpfsResolver();

  const queryKey: QueryKey = ["IPFS_NFT_RESOLVER", ipfsPath];

  const queryFn: QueryFunction<QueryResult, QueryKey> = async ({ queryKey }) => {
    const [, ipfsPath] = queryKey;

    if (!ipfsPath) {
      return null;
    }

    const scrapedCid = scrapeCidFromString(ipfsPath);
    if (!scrapedCid || !validateIpfsCid(scrapedCid)) {
      throw new Error("Invalid IPFS path");
    }

    const nftRangePath = ipfsPath.split("/").concat("metadata.json").join("/");

    return (await load(nftRangePath)) as Nft;
  };

  const isEnabled = !!ipfsPath;

  return useQuery({ queryFn, queryKey, enabled: isEnabled });
};
