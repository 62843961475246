import "./CardContainer.css";
import Typography from "@mui/material/Typography";
import BlockHashIcon from "../../assets/Icons/block-hash.svg";
import TxCard from "../TxCard/TxCard";
import copyToClipboard from "utils/copyToClipboard";
import { memo } from "react";
import {
  BlockMuiCard,
  BlockMuiCardContent,
  BlockHeightTypography,
  CwTxnTypography,
  BlockDataTypography,
  BlockDataDivider,
  BlockCardButton,
} from "./CustomizedMui";
import { BlockChain } from "../TableContainer/CustomizedMui";
import { BlockFragment } from "../../generated/graphql";
import { BlockFragment as devBlockFargment } from "../../generated/graphql-devnet";
import { processTxId } from "utils/processTxId";
import { Fade, Tooltip } from "@mui/material";

type CardContainerProps = {
  time: any;
  displayTime: any;
  block: BlockFragment | devBlockFargment;
  width: any;
  blockchain: BlockChain;
};

const CardContainer = (props: CardContainerProps) => {
  const { time, displayTime, block, width, blockchain } = props;
  const cvTxn = block.l2Tx !== null && block.l2Tx !== undefined ? block.l2Tx.length : 0; //Total Coinweb Transactions in a block
  const txn = cvTxn + block.numberOfTransactions; //Total L1 and L2 Transactions in a block
  const formattedHash = processTxId(block.hash, { network: blockchain.networkName, type: "l1", shouldSlice: true });
  //Function to copy block hash, alert if connection is not in HTTPS

  return (
    <div id={width > 740 ? "Card-container" : "Card-container-mobile"}>
      <div id={width > 740 ? "Inner-container" : "Inner-container-mobile"}>
        <div id="BlockCard-container">
          <BlockMuiCard
            sx={{
              marginBottom: width > 740 ? "0px" : "20px",
              maxWidth: width > 740 ? 450 : undefined,
            }}
            data-aos="fade-up"
            data-aos-delay="100"
            id="Block-mui-card"
          >
            <BlockMuiCardContent>
              <div id="Block-header-container">
                <div id="Block-header-icon">
                  <img id="Block-icon" src={blockchain.icon} alt="bc" />
                </div>
                <div id="Block-header">
                  <BlockHeightTypography align="center" className="BoldFont16">
                    {blockchain.label} Block Height #{block.height}
                  </BlockHeightTypography>
                </div>
                <div id="Header-time">
                  <Typography className="BoldFont14" sx={{ marginBottom: "0px !important" }} gutterBottom>
                    {displayTime}, {time}
                  </Typography>
                </div>
              </div>

              <BlockDataDivider variant="middle" />

              <div id="Coinweb-data-container">
                <div id="Coinweb-data">
                  <CwTxnTypography color="#fff" gutterBottom>
                    {cvTxn}
                  </CwTxnTypography>
                  <Typography className="BoldFont16-dark" sx={{ paddingTop: "10px" }} gutterBottom>
                    Total Coinweb Transactions
                  </Typography>
                </div>
              </div>

              <BlockDataDivider variant="middle" />

              <div id="Data-container">
                <div className="Block-card-table-cell">
                  <Typography className="BoldFont16">Transactions</Typography>
                  <BlockDataTypography className="BoldFont14" gutterBottom>
                    {txn}
                  </BlockDataTypography>
                </div>

                <div className="Block-card-table-cell">
                  <Typography className="BoldFont16">Size (Kb)</Typography>
                  <BlockDataTypography className="BoldFont14" gutterBottom>
                    {(block.size / 1024).toFixed(2)}
                  </BlockDataTypography>
                </div>

                <div className="Block-card-table-cell">
                  <Typography className="BoldFont16">Amount Transacted</Typography>
                  <BlockDataTypography className="BoldFont14" gutterBottom>
                    {blockchain.label} {block.amountTransacted.toFixed(3)}
                  </BlockDataTypography>
                </div>

                <div className="Block-card-table-cell">
                  <Typography className="BoldFont16">Block Hash</Typography>
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "#4f4f72 !important",
                          padding: "0px !important",
                          marginTop: "5px !important",
                        },
                      },
                    }}
                    disableFocusListener
                    TransitionComponent={Fade}
                    title={<div id="TxId-tooltip"> {formattedHash.initial} </div>}
                  >
                    <BlockDataTypography
                      className="BoldFont14"
                      gutterBottom
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        copyToClipboard(formattedHash.initial ?? "", undefined);
                      }}
                    >
                      {formattedHash.text}
                    </BlockDataTypography>
                  </Tooltip>
                </div>
              </div>
              {width > 425 ? (
                <></>
              ) : (
                <div>
                  <div id="Blockhash-icon-container-mobile">
                    <img style={{ margin: "auto" }} className="Blockhash-icon" src={BlockHashIcon} alt={"Block hash"} />
                  </div>
                  <div id="Button-mobile-container">
                    <BlockCardButton
                      type="button"
                      // id="Button-mobile"
                      onClick={() => {
                        copyToClipboard(block.hash);
                      }}
                      variant="contained"
                    >
                      COPY BLOCK HASH
                    </BlockCardButton>
                  </div>
                </div>
              )}
            </BlockMuiCardContent>
          </BlockMuiCard>
        </div>
        <div id={width > 425 ? "TxCards-container" : "Card-container-mobile"}>
          <div data-aos="fade-up" data-aos-anchor="#Block-mui-card" data-aos-delay="200" className="TxCard-row">
            <TxCard
              width={width}
              unit={"USD"}
              title={"Coinweb Transactions"}
              data={block.l2Tx.map((x) => ({ cweb: x }))}
              blockchain={blockchain.networkName}
              height={block.height}
            />
          </div>
          <div data-aos="fade-up" data-aos-anchor="#Block-mui-card" data-aos-delay="300" className="TxCard-row">
            <TxCard
              width={width}
              unit={blockchain.label}
              title={blockchain.label + " Transactions"}
              data={block.tx.map((x) => ({ tx: x }))}
              blockchain={blockchain.networkName}
              height={block.height}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CardContainer);
