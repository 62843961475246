import { create } from "zustand";
import type { TransformedClaimData } from "./types";
import type { Claim } from "@coinweb/claims-client";

interface ClaimsState {
  count: number;
  isLastRange: boolean;
  isLoading: boolean;
  offset: number;
  claims: Claim[];
  transformedClaims: TransformedClaimData[];
  setCount: (count: number) => void;
  setIsLastRange: (isLastRange: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setOffset: (offset: number) => void;
  setClaims: (claims: Claim[]) => void;
  setTransformedClaims: (transformedClaims: TransformedClaimData[]) => void;
}

const useClaimsStore = create<ClaimsState>((set) => ({
  count: 20,
  isLastRange: false,
  isLoading: false,
  offset: 0,
  claims: [],
  transformedClaims: [],
  setCount: (count) => set({ count }),
  setIsLastRange: (isLastRange) => set({ isLastRange }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setOffset: (offset) => set({ offset }),
  setClaims: (claims) => set({ claims }),
  setTransformedClaims: (transformedClaims) => set({ transformedClaims }),
}));

export default useClaimsStore;
