import { type FC } from "react";
import { Divider, Fade, TableCell, TableRow } from "@mui/material";

interface ClaimsTableFlatMapRenderRowDividerProps {
  colSpan: number;
  fadeInDelay?: number;
}

const ClaimsTableFlatMapRenderRowDivider: FC<ClaimsTableFlatMapRenderRowDividerProps> = ({
  colSpan,
  fadeInDelay = 0,
}) => {
  return (
    <Fade in timeout={{ enter: 500, exit: 500 }} style={{ transitionDelay: `${fadeInDelay || 0}ms` }}>
      <TableRow>
        <TableCell colSpan={colSpan} sx={{ height: "2px", borderWidth: 0, paddingTop: 0, paddingBottom: 0 }}>
          <Divider />
        </TableCell>
      </TableRow>
    </Fade>
  );
};

export default ClaimsTableFlatMapRenderRowDivider;
