import { useEffect } from "react";
import { type Claim } from "@coinweb/claims-client";
import type { ClaimsTableConfig, IIFETransformFunction } from "../types";
import ClaimsTableFlatMapRender from "./ClaimsTableFlatMapRender";
import useTransformClaims from "../hooks/useTransformClaims";
import ClaimsTableSkeleton from "../ClaimsTableSkeleton";
import useClaimsStore from "../store";

const ClaimsTableFlatMap = ({
  claims,
  config,
  transformFunction,
  tableIndex,
}: {
  claims: Claim[];
  config: ClaimsTableConfig;
  transformFunction: IIFETransformFunction;
  tableIndex: number;
}) => {
  const {
    transformedClaims: allTransformedClaims,
    setTransformedClaims,
    setIsLoading: setIsLoadingContext,
  } = useClaimsStore();
  const { data: transformedClaims, isLoading, error } = useTransformClaims(config, transformFunction, claims);

  useEffect(() => {
    setIsLoadingContext(isLoading);
  }, [isLoading, setIsLoadingContext]);

  useEffect(() => {
    if (!isLoading && Array.isArray(transformedClaims)) {
      setTransformedClaims(allTransformedClaims.concat(transformedClaims));
    }
  }, [transformedClaims, isLoading, setTransformedClaims]);

  if (isLoading && allTransformedClaims.length === 0) {
    return <ClaimsTableSkeleton withHeader rowsCount={4} />;
  }

  if (error) {
    return <div>{error?.message}</div>;
  }

  if (allTransformedClaims.length > 0) {
    return <ClaimsTableFlatMapRender transformedClaims={allTransformedClaims} tableIndex={tableIndex} />;
  }

  return null;
};

export default ClaimsTableFlatMap;
