import { FileObject } from "hooks/useExtractFilesFromUrl";
import type { ClaimsTableConfig } from "./types";
import { getContractClaimsTableTransformFunction } from "./helpers";
import useFetchClaimsTable from "./hooks/useFetchClaimsTable";
import ClaimsTableMap from "./ClaimsTableMap";
import ClaimsTableFlatMap from "./ClaimsTableFlatMap";
import ClaimsTableSkeleton from "./ClaimsTableSkeleton";
import { useEffect } from "react";
import useClaimsStore from "./store";

const ClaimsTable = ({
  config,
  files,
  tableIndex,
}: {
  config: ClaimsTableConfig;
  files: FileObject;
  tableIndex: number;
}) => {
  const { type, main } = config.transform;

  const { count, offset, setIsLastRange, transformedClaims } = useClaimsStore();
  const { data: claims = [], isLoading } = useFetchClaimsTable(config, { count, offset });

  useEffect(() => {
    if (!isLoading && Array.isArray(claims) && claims.length % count > 0) {
      setIsLastRange(true);
    }
  }, [isLoading, claims, count, setIsLastRange]);

  if (isLoading && transformedClaims.length === 0) {
    return <ClaimsTableSkeleton withHeader rowsCount={4} />;
  }

  if (type === "map") {
    const transformFunction = getContractClaimsTableTransformFunction(files, main);

    if (transformFunction) {
      return <ClaimsTableMap claims={claims} config={config} transformFunction={transformFunction} />;
    }
  }

  if (type === "flatMap") {
    const transformFunction = getContractClaimsTableTransformFunction(files, main);

    if (transformFunction) {
      return (
        <ClaimsTableFlatMap
          claims={claims}
          config={config}
          transformFunction={transformFunction}
          tableIndex={tableIndex}
        />
      );
    }
  }

  return null;
};

export default ClaimsTable;
