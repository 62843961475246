import { NetworkName as ClaimsNetwork } from "@coinweb/claims-client";
import { NetworkName } from "generated/graphql";

const networkDictionary: Record<
  string,
  { clientLibrary: number; graphql: NetworkName }
> = {
  l2v2egld: {
    clientLibrary: ClaimsNetwork.Elrond,
    graphql: NetworkName.Elrond,
  },
  l2v2bnb: { clientLibrary: ClaimsNetwork.Bnb, graphql: NetworkName.Bnb },
  l2v2eth: {
    clientLibrary: ClaimsNetwork.Ethereum,
    graphql: NetworkName.Ethereum,
  },
  l2v2kuji: { clientLibrary: ClaimsNetwork.Kuji, graphql: NetworkName.Kuji },
  l2v2btc: {
    clientLibrary: ClaimsNetwork.Bitcoin,
    graphql: NetworkName.Bitcoin,
  },
  l2v2bch: {
    clientLibrary: ClaimsNetwork.BitcoinCash,
    graphql: NetworkName.BitcoinCash,
  },
  l2v2ltc: {
    clientLibrary: ClaimsNetwork.Litecoin,
    graphql: NetworkName.Litecoin,
  },
  l2v2matic: {
    clientLibrary: ClaimsNetwork.Polygon,
    graphql: NetworkName.Polygon,
  },
  l2v2arb: {
    clientLibrary: ClaimsNetwork.Arbitrum,
    graphql: NetworkName.Arbitrum,
  },
};

export default function translateNetworkString(searchString: string) {
  const key = searchString.toLowerCase().replace(/\s+/g, "");
  return networkDictionary[key] || null;
}
