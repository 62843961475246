import { BlockChain } from "components/TableContainer/CustomizedMui";
import { createContext, useContext, type ReactNode } from "react";

const BlockchainContext = createContext<BlockChain | undefined>(undefined);

// Create the provider component
export const BlockchainDataContextProvider: React.FC<{
  children: ReactNode;
  blockchainData: BlockChain;
}> = ({ children, blockchainData }) => {
  return <BlockchainContext.Provider value={blockchainData}>{children}</BlockchainContext.Provider>;
};

// Create a custom hook to use the context
export const useBlockchainData = (): BlockChain => {
  const context = useContext(BlockchainContext);
  if (!context) {
    throw new Error("useBlockchain must be used within a BlockchainProvider");
  }
  return context;
};
