import { useState } from "react";
import { Fade, Modal, Skeleton } from "@mui/material";
import { TransformedClaimDataImage } from "../types";
import useIpfsCidValidate from "components/Nfts/hooks/useIpfsCidValidate";

const StructureRendererImage: React.FC<{ data: TransformedClaimDataImage }> = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { replaceIpfsPrefixWithHttp } = useIpfsCidValidate();
  const src = data.src.startsWith("ipfs://") ? replaceIpfsPrefixWithHttp(data.src) : data.src;

  return (
    <>
      {isLoading && <Skeleton variant="rectangular" style={{ borderRadius: "4px" }} width="60px" height="60px" />}
      <Fade in={!isLoading} timeout={500}>
        <img
          src={src}
          alt={data.alt}
          crossOrigin="anonymous"
          style={{
            cursor: "pointer",
            width: "60px",
            boxShadow: "0px 0px 44px -14px rgba(255, 255, 255, 0.45)",
            WebkitBoxShadow: "0px 0px 44px -14px rgba(255, 255, 255, 0.45)",
            MozBoxShadow: "0px 0px 44px -14px rgba(255, 255, 255, 0.45)",
            display: isLoading ? "none" : "block",
          }}
          onLoad={() => setIsLoading(false)}
          onClick={() => !isLoading && setModalOpen(true)}
        />
      </Fade>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="Claims Tables Image Modal"
        aria-describedby={data.alt}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={modalOpen} timeout={250} style={{ outline: "none" }}>
          <img src={src} alt={data.alt} crossOrigin="anonymous" style={{ maxHeight: "80%", maxWidth: "80%" }} />
        </Fade>
      </Modal>
    </>
  );
};

export default StructureRendererImage;
