// Import necessary dependencies
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { REACT_APP_ENABLED_NETWORKS } from "conf";
import { NetworkName } from "generated/graphql";
import { BlockChainArray } from "components/TableContainer/CustomizedMui";
import { NetworkName as ClaimsNetwork } from "@coinweb/claims-client";

export default function useChainQueryParameters() {
  const CHAINS = REACT_APP_ENABLED_NETWORKS;
  const [searchParams] = useSearchParams();
  const chain = searchParams.get("chain");

  // Compute the chain index based on the CHAINS array
  const chainIndex = useMemo(() => {
    if (chain && CHAINS.includes(chain as unknown as NetworkName)) {
      return CHAINS.indexOf(chain as unknown as NetworkName);
    }
    return -1;
  }, [chain, CHAINS]);

  // Find the client network name based on the searchQueries
  const clientNetworkName = useMemo(() => {
    if (chain) {
      const lowerCaseChain = chain.toLowerCase().replace(/\s+/g, "");
      const foundChain = BlockChainArray?.find((blockchain) => {
        return (
          blockchain.searchQueries &&
          blockchain.searchQueries.some((query) => query.toLowerCase().replace(/\s+/g, "") === lowerCaseChain)
        );
      });
      return foundChain ? foundChain.fetchNetwork : null;
    }
    return null;
  }, [chain]);

  // Find the corresponding key of the NetworkName enum based on the clientNetworkName
  const networkEnumKey = useMemo(() => {
    if (clientNetworkName !== null && typeof clientNetworkName === "number") {
      const entry = Object.entries(ClaimsNetwork).find(([, value]) => value === clientNetworkName);
      return entry ? entry[0] : null;
    }
    return null;
  }, [clientNetworkName]);

  // Find the index of the found clientNetworkName in BlockChainArray
  const clientNetworkIndex = useMemo(() => {
    if (clientNetworkName) {
      const foundIndex = BlockChainArray?.findIndex((blockchain) => blockchain.fetchNetwork === clientNetworkName);
      return foundIndex !== -1 ? foundIndex : -1;
    }
    return -1;
  }, [clientNetworkName]);

  return {
    index: clientNetworkIndex !== -1 ? clientNetworkIndex + 1 : chainIndex + 1,
    graphqlNetworkName: chain as unknown as NetworkName,
    clientNetworkName: networkEnumKey as keyof typeof ClaimsNetwork,
  };
}
