import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function useConvertHashToSearch() {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const searchHash = searchParams.get("hash");

    if (searchHash && !searchParams.has("search")) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("hash");
      newSearchParams.set("search", searchHash);
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);
}

export default useConvertHashToSearch;
