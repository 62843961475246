import { checkIsDate, timePassedSince, truncateString } from "../helpers";
import { TransformedClaimDataField } from "../types";
import { Tooltip, Typography } from "@mui/material";

const StructureRendererField: React.FC<{ data: TransformedClaimDataField }> = ({ data }) => {
  const MAX_LENGTH = 20;

  if (checkIsDate(data.contents)) {
    const date = checkIsDate(data.contents) as Date;
    const timePassed = timePassedSince(date);
    return (
      <Tooltip
        title={
          <Typography align="center" className="BoldFont14">
            {date.toUTCString()}
          </Typography>
        }
      >
        <Typography align="center" className="BoldFont14">
          {timePassed}
        </Typography>
      </Tooltip>
    );
  }

  if (String(data.contents).length <= MAX_LENGTH) {
    return (
      <Typography align="center" className="BoldFont14">
        {data.contents}
      </Typography>
    );
  }

  return (
    <Tooltip
      title={
        <Typography align="center" className="BoldFont14">
          {data.contents}
        </Typography>
      }
    >
      <Typography align="center" className="BoldFont14">
        {truncateString(String(data.contents), 20)}
      </Typography>
    </Tooltip>
  );
};

export default StructureRendererField;
