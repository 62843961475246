import { REACT_APP_IPFS_GATEWAY } from "conf";
import { CID } from "multiformats/cid";

const useIpfsCidValidate = () => {
  const uriDecodeString = (str: string): string => {
    return decodeURIComponent(str);
  };

  const scrapeCidFromString = (path: string): string | null => {
    const cidRegex =
      /(Qm[1-9A-HJ-NP-Za-km-z]{44}|b[A-Za-z2-7]{58}|B[A-Z2-7]{58}|z[1-9A-HJ-NP-Za-km-z]{48}|F[0-9A-F]{50})/;
    const match = uriDecodeString(path).match(cidRegex);
    if (!match) return null;
    return match[0];
  };

  const validateIpfsCid = (hash: string): boolean => {
    try {
      CID.parse(hash);
      return true;
    } catch (error) {
      return false;
    }
  };

  const replaceIpfsPrefixWithHttp = (path: string): string => {
    return path.replace(/^ipfs:\/\//, (REACT_APP_IPFS_GATEWAY || "https://ipfs.io")?.concat("/ipfs/"));
  };

  return { validateIpfsCid, scrapeCidFromString, replaceIpfsPrefixWithHttp };
};

export default useIpfsCidValidate;
