import { entries } from "lodash/fp";
import { FileObject } from "hooks/useExtractFilesFromUrl";
import convertUint8ArrayToString from "utils/convertUint8ArrayToString";
import { truncateTxId } from "utils/truncateTxId";
import { formatDistanceToNowStrict, isAfter, isBefore } from "date-fns";

export const getContractClaimsTableTransformFunction = (
  contractModuleFiles: FileObject,
  transformModulePath: string
) => {
  if (!contractModuleFiles) {
    return null;
  }

  const existingModuleFile = entries(contractModuleFiles).find(([f]) => f.includes(transformModulePath));

  if (existingModuleFile) {
    const module = convertUint8ArrayToString(existingModuleFile[1]);
    return module;
  }

  return null;
};

export const truncateString = (str: string, length: number) => {
  if (!str) {
    return "";
  }

  if (str.length <= length) {
    return str;
  }

  return truncateTxId(str, length);
};

export const checkIsDate = (date: string | number): Date | undefined => {
  const cutoffDate = new Date("2024-11-01T00:00:00Z");
  const now = new Date();
  let parsedDate: Date;

  if (typeof date === "number" && String(date).length >= 10) {
    parsedDate = new Date(String(date).length === 10 ? date * 1000 : date);
  } else {
    parsedDate = new Date(date);
  }

  if (isBefore(cutoffDate, parsedDate) && isAfter(now, parsedDate)) {
    return parsedDate;
  }

  return;
};

export const timePassedSince = (date: Date): string => {
  const distance = formatDistanceToNowStrict(date); // Returns strings like "2 months", "5 seconds"
  const abbreviations: Record<string, string> = {
    years: "y",
    year: "y",
    months: "m",
    month: "m",
    weeks: "w",
    week: "w",
    days: "d",
    day: "d",
    hours: "h",
    hour: "h",
    minutes: "m",
    minute: "m",
    seconds: "s",
    second: "s",
  };

  return distance
    .split(" ")
    .map((part) => abbreviations[part] || part)
    .join("");
};
