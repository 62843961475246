import React from "react";
import { Box, Chip, ChipProps, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { truncateTxId } from "utils/truncateTxId";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#ffffff",
    border: "1px solid #20214d",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#37385f",
    },
    height: "24px",
    cursor: "pointer",
  },
  label: {
    fontSize: "12px !important",
    fontWeight: "normal !important",
  },
  icon: {
    height: "12px",
    width: "auto",
    objectFit: "contain",
  },
  box: {
    borderRadius: "3px !important",
    fontFamily: '"DINosaur Med", "CheyenneMed", sans-serif !important',
    fontSize: "14px !important",
    fontWeight: "500 !important",
    fontStretch: "normal !important",
    fontStyle: "normal !important",
    lineHeight: "normal !important",
    letterSpacing: "normal !important",
    textAlign: "center",
    color: "#d3d3ef !important",
  },
}));

interface TxChipProps extends ChipProps {
  href: string;
  tx?: string;
  withTooltip?: boolean;
  icon?: React.ReactElement;
}

const TxChip: React.FC<TxChipProps> = ({ tx, icon, withTooltip = true, ...props }) => {
  const classes = useStyles();

  if (withTooltip) {
    return (
      <Tooltip title={<Box className={classes.box}>{tx}</Box>}>
        <Chip
          clickable
          label={tx ? truncateTxId(tx) : props.label}
          icon={icon}
          classes={{
            root: classes.root,
            label: classes.label,
            icon: classes.icon,
          }}
          {...props}
        />
      </Tooltip>
    );
  }

  return (
    <Chip
      label={tx ? truncateTxId(tx) : props.label}
      clickable
      icon={icon}
      classes={{
        root: classes.root,
        label: classes.label,
        icon: classes.icon,
      }}
      {...props}
    />
  );
};

export default TxChip;
