import { WorkerContextWrapper } from "components/Contract/ClaimsTables/worker/WorkerContext";
import { NftBlock } from "./NftBlock";
import { FC } from "react";
import { NftItemProps } from "./types";

export const NftItem: FC<NftItemProps> = ({ ipfs, width }) => {
  return (
    <WorkerContextWrapper>
      <NftBlock ipfs={ipfs} width={width} />
    </WorkerContextWrapper>
  );
};
