import { NetworkName, BlockFragment, FullTxLogGql } from "../../generated/graphql";
import { getBlock, getL2Txs } from "utils/queryRequests";
import { useQueries } from "@tanstack/react-query";
import LoadingBox from "../LoadingBox";
import TableData from "../TableData";
import L2TxLogCard from "../L2TxLogCard/L2TxLogCard";
import ClaimDataCard from "../L2TxLogCard/ClaimDataCard";
import { REACT_APP_ENABLED_NETWORKS } from "../../conf";
import { isNumeric } from "utils/isNumeric";
import { DataNotFoundSection } from "./CustomizedMui";
import useFetchBlocks from "hooks/useFetchBlocks";
import { useEffect, useState } from "react";
import { BlockChains } from "utils/constants";
import { OptionClaim } from "@coinweb/claims-client";
type Props = {
  setNetwork: (n: NetworkName | undefined | 0) => void;
  hash: string;
  width: number;
  onSetSubscription: (value: boolean) => void;
};

const NetworkSelector: React.FC<Props> = ({ setNetwork, hash, width, onSetSubscription }) => {
  const { readClaim } = useFetchBlocks();
  const [fetchClaimRes, setFetchClaimRes] = useState<
    (
      | {
          network: NetworkName;
          claimResult: OptionClaim;
        }
      | {
          network: NetworkName;
          claimResult: null;
        }
      | undefined
    )[]
  >([]);

  const isNumericHash: boolean = isNumeric(hash);
  const blockRes = useQueries({
    queries: REACT_APP_ENABLED_NETWORKS.map((network) => {
      return {
        queryKey: ["block", hash, network],
        queryFn: () => getBlock(hash, network),
        enabled: !isNumericHash,
        staleTime: 1000 * 60 * 60,
      };
    }),
  });
  useEffect(() => {
    if (isNumericHash) {
      const fetchData = async () => {
        const fetchClaimRes = await Promise.all(
          REACT_APP_ENABLED_NETWORKS.map(async (network) => {
            const clientBc = BlockChains[network].fetchNetwork;
            try {
              const claimResult = await readClaim(clientBc)(hash);
              if (claimResult && claimResult !== null) return { network, claimResult };
            } catch (error) {
              console.error("Error fetching claim:", error);
            }
          })
        );
        return fetchClaimRes;
      };

      fetchData().then((fetched) => setFetchClaimRes(fetched));
    }
  }, [readClaim, hash, isNumericHash]);

  const l2TxRes = useQueries({
    queries: REACT_APP_ENABLED_NETWORKS.map((network) => {
      return {
        queryKey: ["l2tx", hash, network],
        queryFn: () => getL2Txs(hash, network),
        enabled: !isNumericHash,
        staleTime: 1000 * 60 * 60,
      };
    }),
  });

  const handleDisplay = () => {
    const allFetchedBlock = blockRes.every((result) => !result.isLoading);
    const allFetchedL2Tx = l2TxRes.every((result) => !result.isLoading);

    if (!allFetchedBlock || !allFetchedL2Tx) return <LoadingBox>Searching...</LoadingBox>;
    if (isNumericHash && fetchClaimRes.length === 0) return <LoadingBox>Searching...</LoadingBox>;

    if (!isNumericHash && allFetchedBlock && allFetchedL2Tx) {
      const filteredBlockRes = blockRes.filter(
        (result: any) => result?.data !== undefined && result?.data?.block !== null
      );
      const filteredL2txRes = l2TxRes.filter(
        (result: any) => result?.data !== undefined && result?.data?.l2TxFullLog?.length > 0
      );

      if (Array.isArray(filteredBlockRes) && filteredBlockRes.length > 0) {
        return (
          <TableData
            width={width}
            data={[(filteredBlockRes[0].data as any)?.block as unknown as BlockFragment]}
            onSetSubscription={onSetSubscription}
            setNetwork={setNetwork}
          />
        );
      }
      if (Array.isArray(filteredL2txRes) && filteredL2txRes.length > 0) {
        return (
          <div>
            {filteredL2txRes.map((log: any, index) => (
              <L2TxLogCard
                key={index}
                width={width}
                data={log?.data?.l2TxFullLog as unknown as FullTxLogGql[]}
                setNetwork={setNetwork}
              />
            ))}
          </div>
        );
      }
    }

    if (isNumericHash && fetchClaimRes) {
      return fetchClaimRes.map((claimRes, index) => {
        if (claimRes) {
          return (
            <div key={index}>
              <ClaimDataCard
                height={hash}
                width={width}
                data={claimRes}
                title="Claims Data Log"
                setNetwork={setNetwork}
              />
            </div>
          );
        }
        return null;
      });
    }
    return DataNotFoundSection;
  };

  return <div>{handleDisplay()}</div>;
};

export default NetworkSelector;
