import type { TransformedClaimData } from "../types";
import ClaimsTableFlatMapRenderPaginationLoadMore from "./ClaimsTableFlatMapRenderPaginationLoadMore";
import ClaimsTableFlatMapRenderPaginationNav from "./ClaimsTableFlatMapRenderPaginationNav";

const ClaimsTableFlatMapRenderPagination = ({
  colSpan,
  page,
  count,
  setCount,
  setPage,
  transformedClaims,
}: {
  colSpan: number;
  page: number;
  count: number;
  setCount: (count: number) => void;
  setPage: (page: number) => void;
  transformedClaims: TransformedClaimData[];
}) => {
  return (
    <>
      <ClaimsTableFlatMapRenderPaginationLoadMore
        colSpan={colSpan}
        page={page}
        count={count}
        transformedClaims={transformedClaims}
      />
      <ClaimsTableFlatMapRenderPaginationNav
        colSpan={colSpan}
        page={page}
        count={count}
        setPage={setPage}
        setCount={setCount}
        transformedClaims={transformedClaims}
      />
    </>
  );
};

export default ClaimsTableFlatMapRenderPagination;
